import {
  Flex,
  hanghae,
  InfoTimer,
  neutralDay,
  Text,
} from '@teamsparta/design-system';
import { Separated } from '@teamsparta/react';

import type { PollOption } from '@/api/community/post';
import CustomLink from '@/components/common/CustomLink';
import { Dot } from '@/components/common/Dot';
import { LoggingClick } from '@/components/common/LoggingClick';
import { adjustDateByPurpose } from '@/lib/utils/date';
import { formatRemainingTimeToText } from '@/pages/community/logic';

import * as S from './style';

interface Props {
  title: string;
  postId: string;
  pollEndDate: string;
  pollOptions: PollOption[];
}

export function OngoingPollPost({
  title,
  postId,
  pollEndDate,
  pollOptions,
}: Props) {
  const totalSelectedCount = pollOptions.reduce(
    (acc, curr) => acc + curr.selectedCount,
    0,
  );

  return (
    <LoggingClick
      logName='hh_community_click'
      data={{ section: '진행 중인 투표', clicked_post_id: postId }}
    >
      <CustomLink href={`/community/post/${postId}`} fullWidth>
        <S.OngoingPollWrapper>
          <Flex.Column key={title} gap={{ mobile: 4, desktop: 2 }}>
            <Text
              as='span'
              font={{ mobile: 'mBody3', desktop: 'wBody3' }}
              color={neutralDay.gray20}
              style={{
                maxWidth: '180px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                wordBreak: 'break-all',
              }}
            >
              {title}
            </Text>
            <Flex.Row gap={4} align='center'>
              <Separated with={<Dot />}>
                <Flex.Row gap={2} align='center'>
                  <InfoTimer size={16} color={hanghae.red60} style='line' />
                  <Text as='span' font='wCaption2' color={hanghae.red60}>
                    {formatRemainingTimeToText(
                      adjustDateByPurpose(pollEndDate, 'calc'),
                    )}
                  </Text>
                </Flex.Row>
                <Text as='span' font='wCaption2' color={neutralDay.gray70}>
                  {totalSelectedCount}명 참여
                </Text>
              </Separated>
            </Flex.Row>
          </Flex.Column>
        </S.OngoingPollWrapper>
      </CustomLink>
    </LoggingClick>
  );
}
