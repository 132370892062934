import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { Divider, Flex, neutralDay, Text } from '@teamsparta/design-system';
import { InView, Separated, When } from '@teamsparta/react';
import { isEmptyArray } from '@teamsparta/utils';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';

import Only from '@/components/common/Only';
import { Skeleton } from '@/components/common/skeleton';
import { Suspense } from '@/components/common/Suspense';
import type { CommunityPostCategory } from '@/constants';
import { MINUTE } from '@/constants';
import { communityPostsInfiniteQuery } from '@/hooks/react-query/community/post';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from '@/lib/utils/session-storage';
import { createCDNUrl } from '@/lib/utils/url';

import { Categories } from '../Categories';
import { OngoingPollPosts } from '../OngoingPollPosts';
import { Post } from '../Post';

const SCROLL_Y_STORAGE_KEY = 'community-feed-scroll-y';
type StoredPosition = {
  y: number;
  timestamp: number;
  postId: string;
  category: CommunityPostCategory;
};

const QUERY_EXPIRE_TIME = 20 * MINUTE;

/**
 * @todo community 넓이가 줄어들면 카테고리가 tabs처럼 슬라이딩 가능해야 함
 * @todo community 훅으로 분리하기
 */
export function Feed() {
  const [category, setCategory] = useState<CommunityPostCategory>('전체');
  const {
    data: { pages },
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useSuspenseInfiniteQuery({
    ...communityPostsInfiniteQuery({ category }),
    gcTime: QUERY_EXPIRE_TIME,
  });
  const posts = pages.flatMap((page) => page.posts) ?? [];

  function handleEnter() {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }

  function handleChangeCategory(category: CommunityPostCategory) {
    setCategory(category);

    window.scrollTo(0, 0);
    removeSessionStorageItem(SCROLL_Y_STORAGE_KEY);
  }

  /**
   * 스크롤 복원 관련
   */
  const router = useRouter();

  // 스크롤 위치 저장
  useEffect(() => {
    function handleRouteChange() {
      setSessionStorageItem(SCROLL_Y_STORAGE_KEY, {
        y: window.scrollY,
        timestamp: Date.now(),
        category,
      });
    }

    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [category, router.events]);

  // 스크롤 위치 복원
  useEffect(() => {
    const storedPosition =
      getSessionStorageItem<StoredPosition>(SCROLL_Y_STORAGE_KEY);
    if (!storedPosition) {
      return;
    }

    const isExpired = Date.now() - storedPosition.timestamp > QUERY_EXPIRE_TIME;
    if (isExpired || storedPosition.category !== category) {
      removeSessionStorageItem(SCROLL_Y_STORAGE_KEY);
      return;
    }

    window.scrollTo(0, storedPosition.y);
    removeSessionStorageItem(SCROLL_Y_STORAGE_KEY);
  }, [category]);

  return (
    <Flex.Column gap={{ mobile: 0, desktop: 16 }} fullWidth>
      <Categories value={category} onChange={handleChangeCategory} />
      <When condition={!isEmptyArray(posts)} fallback={<Empty />}>
        <Flex.Column gap={{ mobile: 0, desktop: 16 }} fullWidth>
          <Separated
            with={
              <Only.Mobile>
                <Divider color={neutralDay.gray95} />
              </Only.Mobile>
            }
          >
            {posts
              ?.slice(0, 5)
              .map((post) => (
                <Post selectedCategory={category} key={post._id} {...post} />
              ))}
            <Only.Mobile>
              <Suspense clientOnly fallback={<OngoingPollPosts.Skeleton />}>
                <OngoingPollPosts />
              </Suspense>
            </Only.Mobile>
            {posts
              ?.slice(5)
              .map((post) => (
                <Post selectedCategory={category} key={post._id} {...post} />
              ))}

            <When
              condition={!isFetchingNextPage}
              fallback={<Feed.Skeleton withCategories={false} />}
            >
              <InView onEnter={handleEnter}>
                <div style={{ height: 100 }} />
              </InView>
            </When>
          </Separated>
        </Flex.Column>
      </When>
    </Flex.Column>
  );
}

function Empty() {
  return (
    <Flex.Column
      padding='80px 0'
      justify='center'
      align='center'
      fullWidth
      gap={12}
    >
      <Only.Mobile>
        <Image
          src={createCDNUrl('/hanghae99/community/writing-hand.webp')}
          alt=''
          width={24}
          height={24}
        />
      </Only.Mobile>
      <Only.Desktop>
        <Image
          src={createCDNUrl('/hanghae99/community/writing-hand.webp')}
          alt=''
          width={30}
          height={30}
        />
      </Only.Desktop>
      <Text
        as='span'
        font='mTitle2'
        color={neutralDay.gray40}
        style={{ whiteSpace: 'pre-wrap' }}
        align='center'
      >
        {'아직 글이 없어요\n첫 글을 작성해 보세요'}
      </Text>
    </Flex.Column>
  );
}

function _Skeleton({ withCategories = true }: { withCategories?: boolean }) {
  return (
    <Flex.Column gap={{ mobile: 0, desktop: 16 }} fullWidth>
      {withCategories && <Categories />}
      <Flex.Column gap={{ mobile: 0, desktop: 16 }} fullWidth>
        {[0, 1, 2].map((order) => (
          <Fragment key={order}>
            <Only.Desktop>
              <When
                condition={order % 2 === 0}
                fallback={
                  <Skeleton style={{ width: '100%', height: '158px' }} />
                }
              >
                <Skeleton
                  key={order}
                  style={{ width: '100%', height: '266px' }}
                />
              </When>
            </Only.Desktop>
            <Only.Mobile>
              <Flex fullWidth padding='24px 16px'>
                <When
                  condition={order % 2 === 0}
                  fallback={
                    <Skeleton style={{ width: '100%', height: '101px' }} />
                  }
                >
                  <Skeleton
                    key={order}
                    style={{ width: '100%', height: '203px' }}
                  />
                </When>
              </Flex>
            </Only.Mobile>
          </Fragment>
        ))}
      </Flex.Column>
    </Flex.Column>
  );
}

Feed.Skeleton = _Skeleton;
