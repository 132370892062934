import * as RadixPopover from '@radix-ui/react-popover';

import * as S from './style';

export const Popover = {
  Root: RadixPopover.Root,
  Trigger: RadixPopover.Trigger,
  Portal: RadixPopover.Portal,
  Content: S.PopoverContent,
};
