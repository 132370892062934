import { useEffect, useRef } from 'react';

/**
 * 이정도의 로직도 따로 hook으로 분리할만한 가치가 있을까?
 */
export function useAutoFocus<T extends HTMLElement>() {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return ref;
}
