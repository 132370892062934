import styled from '@emotion/styled';
import { neutralDay } from '@teamsparta/design-system';
import { clamp } from '@teamsparta/utils';

export const PollProgressBar = styled.div<{
  percentage: number;
  isMostSelectedOption: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(
    ${({ percentage }) => `calc(-100% + ${clamp(percentage, 0, 100)}%)`}
  );
  height: 100%;
  background-color: ${({ isMostSelectedOption }) =>
    isMostSelectedOption ? 'rgba(255, 92, 92, 0.24)' : neutralDay.gray90};
  border-radius: 999px;
  z-index: 0;
`;
