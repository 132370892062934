import * as amplitude from '@amplitude/analytics-browser';
import { webAttributionPlugin } from '@amplitude/plugin-web-attribution-browser';
import { isClient } from '@teamsparta/utils';

export const initAmplitude = () => {
  if (isClient()) {
    if (window['amplitude']) {
      return;
    }
    amplitude.add(webAttributionPlugin());
    amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY || '', undefined, {
      attribution: {
        excludeReferrers: [
          'kauth.kakao.com',
          'spartacodingclub.kr',
          'online.spartacodingclub.kr',
          'logins.daum.net',
        ],
      },
    });
    amplitude.setSessionId(Date.now());
    window['amplitude'] = amplitude;
  }
};
