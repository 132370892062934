import type { ImageProps } from 'next/image';
import Image from 'next/image';

interface Props extends Omit<ImageProps, 'src'> {
  src?: string;
}

export function ProfileImage({
  src,
  alt,
  width,
  height,
  style,
  ...props
}: Props) {
  return (
    <div
      style={{
        position: 'relative',
        width,
        height,
        borderRadius: '50%',
        overflow: 'hidden',
      }}
    >
      <Image
        src={src ?? '/svg/community/default-profile.svg'}
        alt={alt}
        fill
        style={{
          ...style,
          objectFit: 'cover',
        }}
        onError={(event) => {
          event.currentTarget.src = '/svg/community/default-profile.svg';
        }}
        unoptimized
        {...props}
      />
    </div>
  );
}
