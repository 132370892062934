export function getUserInfoFromClientCookie() {
  let output = { _id: '', name: '', phone: '', email: '', is_kko: false };

  getCookie('userinfo')
    ?.split(/\s*&\s*/)
    .forEach(function (pair) {
      let name = decodeURIComponent(pair.split('=')[0]);
      output[name] = decodeURIComponent(pair.split('=')[1]);
    });
  return output;
}

export function getOnlineUserIdFromServerCookie(req) {
  let onlineUserId = null;
  const cookie = req.headers.cookie;
  if (cookie) {
    const cookies = cookie.split(';').map((c) => c.trim());
    const userInfoCookie = cookies.find((c) => c.startsWith('userinfo='));

    if (userInfoCookie) {
      onlineUserId = decodeURIComponent(userInfoCookie).split('=')[2];

      if (onlineUserId.includes('&')) {
        const kakaoOnlineUserId = onlineUserId;
        onlineUserId = kakaoOnlineUserId.split('&')[0];
      }
    }
  }
  return onlineUserId;
}

export function setCookie(name, value, days) {
  let expires = '';
  const domain = document.location.origin.includes('.spartacodingclub.kr')
    ? '.spartacodingclub.kr'
    : document.location.hostname;
  const sameSite = document.location.origin.includes('.spartacodingclub.kr')
    ? '; SameSite=None; Secure'
    : '';
  let date = new Date();
  date.setTime(
    days ? date.getTime() + days * 24 * 60 * 60 * 1000 : date.getTime(),
  );
  expires = `; domain=${domain}; expires=${date.toUTCString()}${sameSite}`;
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return decodeURIComponent(parts.pop().split(';').shift());
  }
}

export function getCookieForSSR(name: any, cookie = '') {
  if (typeof document !== 'undefined') {
    cookie = cookie || document.cookie;
  }
  return cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith(`${name}=`))
    ?.replace(`${name}=`, '')
    ? decodeURIComponent(
        cookie
          .split(';')
          .map((cookie) => cookie.trim())
          .find((cookie) => cookie.startsWith(`${name}=`))
          ?.replace(`${name}=`, ''),
      )
    : undefined;
}

export const parseCookie = (str: string) =>
  str
    ? str
        .split('&')
        .map((v) => v.split('='))
        .reduce((acc: { [key: string]: any }, v) => {
          acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
            v[1].trim(),
          );
          return acc;
        }, {})
    : undefined;
