import { useBreakpointContext } from '@teamsparta/design-system';
import type { CSSProperties, ElementType } from 'react';
import { type PropsWithChildren } from 'react';

import { breakpoints } from '@/styles/themes';

import * as S from './style';

/**
 * Only를 사용하여 특정 조건에서만 보여줄 수 있습니다.
 * @description Only.Mobile, Only.Desktop을 사용하여 모바일, 데스크탑에서만 보여줄 수 있습니다.
 * @param children
 * @param condition 조건
 */
export default function Only({
  children,
  condition,
}: PropsWithChildren<{ condition?: boolean }>) {
  return condition ? <>{children}</> : null;
}

interface Props {
  as?: ElementType;
  style?: CSSProperties;
}

function Mobile({ children, as, style }: PropsWithChildren<Props>) {
  const { desktop } = useBreakpointContext('Only.Mobile');

  return (
    <S.MobileOnly
      breakpoint={desktop ?? breakpoints.desktop}
      as={as}
      style={style}
    >
      {children}
    </S.MobileOnly>
  );
}

function Desktop({ children, as, style }: PropsWithChildren<Props>) {
  const { desktop } = useBreakpointContext('Only.Desktop');

  return (
    <S.DesktopOnly
      breakpoint={desktop ?? breakpoints.desktop}
      as={as}
      style={style}
    >
      {children}
    </S.DesktopOnly>
  );
}

Only.Mobile = Mobile;
Only.Desktop = Desktop;
