import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  getNotificationByRoundIdAndOnlineUserId,
  postNotification,
} from '@/api/common/notification';
import { getNotifiableRoundByCourseName } from '@/api/common/round';
import type { Course } from '@/types';

export const notificationQueryKeys = {
  all: ['notification'],
  notifiableRoundByCourseName: (courseName: Course) => [
    'notification',
    courseName,
  ],
  notificationByRoundIdAndOnlineUserId: (
    roundId: string,
    onlineUserId: string,
  ) => ['notification', roundId, onlineUserId],
};

export function useGetNotifiableRound({
  courseName,
  enabled = true,
}: {
  courseName: Course;
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: notificationQueryKeys.notifiableRoundByCourseName(courseName),
    queryFn: () => getNotifiableRoundByCourseName(courseName),
    enabled: !!courseName && enabled,
    select: (round) => {
      if (!round) {
        return null;
      }

      return round;
    },
  });
}

export function useGetNotification({
  roundId,
  onlineUserId,
}: {
  roundId: string;
  onlineUserId: string;
}) {
  return useQuery({
    queryKey: notificationQueryKeys.notificationByRoundIdAndOnlineUserId(
      roundId,
      onlineUserId,
    ),
    queryFn: () =>
      getNotificationByRoundIdAndOnlineUserId(roundId, onlineUserId),
    enabled: !!roundId && !!onlineUserId,
    refetchOnWindowFocus: false,
  });
}

export function usePostNotification() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postNotification,
    onError: (_, data) => {
      void _;

      queryClient.invalidateQueries({
        queryKey: notificationQueryKeys.notificationByRoundIdAndOnlineUserId(
          data.roundId,
          data.onlineUserId,
        ),
      });
    },
  });
}
