import { useLoadableRemoteConfig } from '@hackler/react-sdk';
import { atom } from 'jotai';
import type { MutableRefObject } from 'react';

type OffsetTopList = number[];
export type Section = 'intro' | 'partner' | 'careerSupport' | 'schedule';
type SectionRefList = SectionRef[];

export const offsetTopListAtom = atom<OffsetTopList>([0, 1, 2, 3]);
export const activeSectionAtom = atom<Section>('intro');
export type SectionRef = MutableRefObject<any>;

const mobileNavHeight = 36;

const getOffsetTop = (ref: SectionRef): number => {
  return ref && ref.current ? ref.current.offsetTop - mobileNavHeight : 0;
};
const getOffsetTopList = (refs: SectionRefList): number[] => {
  return refs.map((ref) => getOffsetTop(ref));
};

export const updateOffsetTopListAtom = atom(
  (get) => get(offsetTopListAtom),
  (get, set, sectionRefList: SectionRefList) => {
    set(offsetTopListAtom, getOffsetTopList(sectionRefList.sort()));
  },
);

export const useCustomRemoteConfig = <T = any>(key: string): T => {
  const { isLoading, remoteConfig } = useLoadableRemoteConfig();
  const parameterValue = remoteConfig.get(key, 'defaultValue');
  const isRemoteConfigOn = !isLoading && parameterValue !== 'defaultValue';
  return isRemoteConfigOn ? JSON.parse(parameterValue) : null;
};
