import { Logger } from './logger';

/**
 * 상태 전환을 정의하는 인터페이스입니다.
 * @template S 상태의 타입
 * @template E 이벤트의 타입
 */
interface StateTransition<S, E> {
  nextState: S;
  onEvent: E;
  condition?: (currentState: S, event: E, stack: S[], events: E[]) => boolean;
}

/**
 * 상태 머신의 구조를 정의하는 인터페이스입니다.
 * @template S 상태의 타입 (문자열)
 * @template E 이벤트의 타입 (문자열)
 */
export interface StateMachine<S extends string, E extends string> {
  [state: string]: StateTransition<S, E>[];
}

/**
 * 상태 머신을 구축하는 빌더 클래스입니다.
 * @template S 상태의 타입 (문자열)
 * @template E 이벤트의 타입 (문자열)
 */
export class StateMachineBuilder<S extends string, E extends string> {
  private stateMachine: StateMachine<S, E> = {};

  /**
   * 상태 전환을 추가합니다.
   * @param state 현재 상태
   * @param event 발생 이벤트
   * @param nextState 다음 상태
   * @param condition 전환 조건 (선택적)
   * @returns 빌더 인스턴스
   */
  addTransition(
    state: S,
    event: E,
    nextState: S,
    condition?: (currentState: S, event: E, stack: S[], events: E[]) => boolean,
  ): StateMachineBuilder<S, E> {
    if (!this.stateMachine[state]) {
      this.stateMachine[state] = [];
    }
    this.stateMachine[state].push({ nextState, onEvent: event, condition });
    return this;
  }

  /**
   * 구축된 상태 머신을 반환합니다.
   * @returns 완성된 상태 머신
   */
  build(): StateMachine<S, E> {
    return this.stateMachine;
  }
}

/**
 * 상태 머신을 처리하는 핸들러 클래스입니다.
 * @template S 상태의 타입 (문자열)
 * @template E 이벤트의 타입 (문자열)
 */
export class StateMachineHandler<S extends string, E extends string> {
  private stateMachine: StateMachine<S, E>;
  private currentState: S;
  private stateStack: S[];
  private initialState: S;
  private backEvent: E;
  private events: E[];
  private debug: boolean;

  /**
   * StateMachineHandler의 생성자입니다.
   * @param stateMachine 상태 머신 정의
   * @param initialState 초기 상태
   * @param backEvent 이전 상태로 돌아가는 이벤트
   */
  constructor(
    stateMachine: StateMachine<S, E>,
    initialState: S,
    backEvent: E,
    debug = false,
  ) {
    Logger.setLogLevel('debug');
    this.stateMachine = stateMachine;
    this.currentState = initialState;
    this.initialState = initialState;
    this.stateStack = [];
    this.backEvent = backEvent;
    this.events = [];
    this.debug = debug;
  }

  /**
   * 디버그 로그를 출력합니다.
   * @param args 로그 인자
   */
  private log(level: 'info' | 'error' | 'debug', message: string): void {
    if (!this.debug) {
      return;
    }

    switch (level) {
      case 'info':
        Logger.info('StateMachine', message);
        break;
      case 'error':
        Logger.error('StateMachine', message);
        break;
      case 'debug':
        Logger.debug('StateMachine', message);
        break;
    }
  }

  /**
   * 주어진 이벤트에 따라 상태를 전환합니다.
   * @param event 발생 이벤트
   * @returns 새로운 현재 상태
   */
  transition(event: E): S {
    this.events.push(event);

    this.log('debug', `이벤트 발생: ${event}`);

    if (event === this.backEvent) {
      if (this.stateStack.length > 0) {
        const prevState = this.currentState;
        this.currentState = this.stateStack.pop()!;
        this.log('info', `상태 되돌리기: ${prevState} → ${this.currentState}`);
      } else {
        this.log('error', '되돌릴 상태가 없습니다');
      }
    } else {
      const transitions = this.stateMachine[this.currentState];
      if (!transitions) {
        this.log(
          'error',
          `${this.currentState} 상태에 대한 전환이 정의되지 않음`,
        );
        return this.currentState;
      }

      const validTransition = transitions.find(
        (transition) =>
          transition.onEvent === event &&
          (!transition.condition ||
            transition.condition(
              this.currentState,
              event,
              this.stateStack,
              this.events,
            )),
      );

      if (validTransition) {
        this.stateStack.push(this.currentState);
        const prevState = this.currentState;
        this.currentState = validTransition.nextState;
        this.log('info', `상태 전환: ${prevState} → ${this.currentState}`);
      } else {
        this.log(
          'error',
          `${this.currentState} 상태에서 ${event} 이벤트에 대한 유효한 전환이 없음`,
        );
      }
    }

    return this.currentState;
  }

  /**
   * 현재 상태를 반환합니다.
   * @returns 현재 상태
   */
  getCurrentState(): S {
    return this.currentState;
  }

  /**
   * 지정된 상태로 직접 이동합니다.
   * @param state 이동할 상태
   */
  moveToState(state: S): void {
    this.stateStack.push(this.currentState);
    const prevState = this.currentState;
    this.currentState = state;
    this.log('info', `강제 상태 전환: ${prevState} → ${this.currentState}`);
  }

  /**
   * 상태 머신을 초기 상태로 재설정합니다.
   */
  reset(): void {
    const prevState = this.currentState;
    this.currentState = this.initialState;
    this.stateStack = [];
    this.events = [];
    this.log('info', `상태 머신 초기화: ${prevState} → ${this.initialState}`);
  }
}
