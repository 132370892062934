import {
  ActionThumbUp,
  Flex,
  neutralDay,
  Text,
} from '@teamsparta/design-system';
import type { MouseEvent } from 'react';

interface Props {
  count?: number;
  liked?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export function LikeButton({ count = 0, liked = false, onClick }: Props) {
  return (
    <Flex.Row align='center' gap={3} as='button' onClick={onClick}>
      <ActionThumbUp
        size={16}
        style={liked ? 'fill' : 'line'}
        color={neutralDay.gray20}
      />
      <Text as='span' font='mBody3' color={neutralDay.gray20}>
        {count}
      </Text>
    </Flex.Row>
  );
}
