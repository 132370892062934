import {
  Button,
  ContentsEdit,
  Flex,
  neutralDay,
  Text,
} from '@teamsparta/design-system';
import { When } from '@teamsparta/react';

import { useIsDesktop } from '@/hooks';
import { breakpoints } from '@/styles/themes';

interface Props {
  onEdit?: () => void;
}

export function EditButton({ onEdit }: Props) {
  const isDesktop = useIsDesktop({ breakpoint: breakpoints.largeTablet });

  return (
    <When
      condition={isDesktop}
      fallback={
        <Flex.Row
          as='button'
          padding='13px 0'
          gap={8}
          onClick={onEdit}
          aria-label='수정하기'
          align='center'
          fullWidth
        >
          <ContentsEdit size={16} color={neutralDay.gray0} />
          <Text as='button' font='mBody2' color={neutralDay.white}>
            수정하기
          </Text>
        </Flex.Row>
      }
    >
      <Button
        leftAddon={<ContentsEdit size={16} color={neutralDay.gray0} />}
        fullWidth
        size='xs'
        colorScheme='tertiary'
        onClick={onEdit}
      >
        수정하기
      </Button>
    </When>
  );
}
