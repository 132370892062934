import { atom, useAtom } from 'jotai';

export const editProfileModalAtom = atom(false);

export function useEditProfileModal() {
  const [open, setOpen] = useAtom(editProfileModalAtom);

  return {
    value: open,
    open: () => setOpen(true),
    close: () => setOpen(false),
  };
}
