import type { ButtonProps } from '@teamsparta/design-system';
import { Button } from '@teamsparta/design-system';

interface Props extends ButtonProps {
  active?: boolean;
}

export function Category({ active = false, children, style, ...props }: Props) {
  return (
    <Button
      variant={active ? 'solid' : 'outline'}
      radius='rounded'
      size='xs'
      colorScheme='secondary'
      style={{ border: '1px solid rgb(106, 113, 119)', ...style }}
      {...props}
    >
      {children}
    </Button>
  );
}
