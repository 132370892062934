import { additional, Flex, Text } from '@teamsparta/design-system';
import type {
  DetailedHTMLProps,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactNode,
} from 'react';
import { forwardRef, useEffect, useRef } from 'react';

import { composeRefs } from '@/lib/utils/compose-ref';

import * as S from './style';

const Root = forwardRef<
  HTMLTextAreaElement,
  DetailedHTMLProps<
    InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > & {
    label?: ReactNode;
    helperText?: ReactNode;
    addon?: ReactNode;
  }
>(({ addon, label, helperText, onChange, ...props }, ref) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const closestFormRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (!textAreaRef.current) {
      return;
    }

    const closestForm = textAreaRef.current.closest('form');
    if (closestForm) {
      closestFormRef.current = closestForm;
    }
  }, []);

  function adjustHeight() {
    const textArea = textAreaRef.current;
    if (!textArea) {
      return;
    }

    textArea.style.height = 'auto';
    textArea.style.height = textArea.scrollHeight + 'px';
  }

  useEffect(() => {
    adjustHeight();
  }, [props.value]);

  return (
    <Flex.Column fullWidth gap={6}>
      {label && label}
      <S.InputContainer onClick={() => textAreaRef.current?.focus()}>
        <S.TextArea
          ref={composeRefs(ref, textAreaRef)}
          onChange={(event) => {
            onChange?.(event);
            adjustHeight();
          }}
          rows={1}
          {...props}
        />
        {addon && addon}
      </S.InputContainer>
      {helperText && helperText}
    </Flex.Column>
  );
});

Root.displayName = 'TextArea.Root';

const Label = forwardRef<
  HTMLLabelElement,
  DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
>(({ children, ...props }, ref) => {
  return (
    <S.Label ref={ref} {...props}>
      {children}
    </S.Label>
  );
});

Label.displayName = 'TextArea.Label';

function HelperText({ children }: { children: ReactNode }) {
  return (
    <Text as='span' font='wCaption1' color={additional.red100}>
      {children}
    </Text>
  );
}

export const TextArea = {
  Root,
  Label,
  HelperText,
};
