import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { refresh, verify } from '@/api/auth';
import { useToken } from '@/hooks/user';
import {
  NoneTokenException,
  RefreshTokenExpiredException,
  RefreshTokenInvalidException,
  RefreshTokenRevokedException,
  StatusCode,
} from '@/lib/exceptions';
import { useLoginModal } from '@/store/login';
import { authStore, onlineUserInfoAtom, tokenAtom } from '@/store/user';

const authQueryKey = {
  all: ['auth'] as const,
  verify: () => [...authQueryKey.all, 'verify'] as const,
  refresh: () => [...authQueryKey.all, 'refresh'] as const,
};

export function useAuthorize() {
  const { token, isLoading: isTokenLoading } = useToken();

  const { mutateAsync: verify } = useVerify();
  const { mutateAsync: refresh } = useRefresh();

  const authorize = useCallback(async () => {
    let authorized = false;

    if (isTokenLoading) {
      return authorized;
    }

    if (!token) {
      return authorized;
    }

    await verify(token)
      .then(({ is_logined }) => {
        if (!is_logined) {
          authorized = false;
          return;
        }

        authorized = true;
      })
      .catch(() => {
        const refreshToken = getCookie('refresh');

        refresh(refreshToken ?? '')
          .then(() => {
            authorized = true;
          })
          .catch(() => {
            authorized = false;
          });
      });

    return authorized;
  }, [isTokenLoading, refresh, token, verify]);

  return authorize;
}

export function useVerify() {
  return useMutation({
    mutationKey: authQueryKey.verify(),
    mutationFn: verify,
    onSuccess: ({ is_logined }) => {
      if (!is_logined) {
        deleteCookie('token');
        deleteCookie('refresh');
        deleteCookie('userinfo');
        authStore.set(onlineUserInfoAtom, null);
        authStore.set(tokenAtom, { isLoading: false, token: null });
      }
    },
  });
}

export function useRefresh() {
  return useMutation({
    mutationKey: authQueryKey.refresh(),
    mutationFn: refresh,
    onSuccess: (newAccessToken) => {
      setCookie('token', newAccessToken);
      authStore.set(tokenAtom, {
        isLoading: false,
        token: newAccessToken,
      });
    },
    onError: (refreshError) => {
      if (!(refreshError instanceof AxiosError)) {
        throw refreshError;
      }

      switch (refreshError.response?.status) {
        case StatusCode.RefreshTokenExpired:
        case StatusCode.RefreshTokenInvalid:
        case StatusCode.RefreshTokenRevoked:
          deleteCookie('token');
          deleteCookie('refresh');
          deleteCookie('userinfo');
          authStore.set(onlineUserInfoAtom, null);
          authStore.set(tokenAtom, { isLoading: false, token: null });
          break;
        default:
          throw refreshError;
      }
    },
    retry: false,
  });
}

interface UseAuthMutationOptions<TData, TError, TVariables, TContext>
  extends Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationFn'
  > {
  mutationFn: (variables: TVariables) => Promise<TData>;
}

export function useAuthMutation<TData, TError, TVariables, TContext>(
  options: UseAuthMutationOptions<TData, TError, TVariables, TContext>,
) {
  const router = useRouter();
  const { open, setNextPage } = useLoginModal();
  const { token } = useToken();
  const queryClient = useQueryClient();

  return {
    ...useMutation({
      ...options,
      mutationFn: async (variables) => {
        try {
          /**
           * @todo community token이 없을 때는 요청 안 보내고 바로 로그인 모달 띄우기
           */
          if (!token) {
            open();
            setNextPage(router.asPath);

            throw new NoneTokenException('token is null');
          }

          return await options.mutationFn(variables);
        } catch (error) {
          /**
           * @todo community 이거 확인하는 함수 따로 만들어서 사용하기
           */
          if (
            error instanceof RefreshTokenExpiredException ||
            error instanceof RefreshTokenInvalidException ||
            error instanceof RefreshTokenRevokedException
          ) {
            open();
            setNextPage(router.asPath);

            if (token) {
              await queryClient.resetQueries();
            }
          }

          throw error;
        }
      },
    }),
  };
}
