import styled from '@emotion/styled';
import {
  mBody1,
  mBody4,
  neutralDark,
  neutralDay,
  wBody1,
  wBody4,
} from '@teamsparta/design-system';

import { LARGE_TABLET } from '@/styles/themes';

export const PostContainer = styled.div`
  width: 100%;
  max-width: 820px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  ${LARGE_TABLET} {
    padding: 20px;
    max-width: 580px;
    gap: 16px;
    background: ${neutralDark.gray0};
  }
`;

export const PostTitle = styled.h3`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  ${mBody1};
  color: ${neutralDay.gray5};

  ${LARGE_TABLET} {
    ${wBody1};
    color: ${neutralDay.gray5};
  }
`;

export const PostContent = styled.p`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  ${mBody4};
  color: ${neutralDark.gray70};

  ${LARGE_TABLET} {
    ${wBody4};
    color: ${neutralDark.gray70};
  }
`;
