import { useRouter } from 'next/router';
import { useEffect } from 'react';

/**
 * @link https://www.grouparoo.com/blog/getting-previous-path-nextjs
 *
 * @description sessionStorage는 현재 떠 있는 탭 내에서만 유지됨. 탭을 닫으면 제거됨.
 *              반영구적으로 저장해야될 큰 데이터는 아니라고 판단하여 localStorage 대신 사용.
 * @link        https://ko.javascript.info/localstorage#ref-94
 */
export function usePreviousPath() {
  const router = useRouter();

  useEffect(() => {
    function storePath() {
      const storage = globalThis.sessionStorage;

      if (!storage) {
        return;
      }

      const previousPath = storage.getItem('hanghae-current-path');

      storage.setItem('hanghae-previous-path', previousPath ?? '');
      storage.setItem('hanghae-current-path', router.asPath);
    }

    storePath();
  }, [router.asPath, router.events]);
}
