import { LoggingClick } from '@/components/common/LoggingClick';
import type { CommunityPostCategory } from '@/constants';
import { COMMUNITY_POST_CATEGORIES } from '@/constants';
import { useScrollDirection } from '@/lib/utils/businessLogics/_common/useScrollDirection';

import { Category } from '../Category';
import * as S from './style';

interface Props {
  value?: CommunityPostCategory;
  onChange?: (category: CommunityPostCategory) => void;
}

export function Categories({ value = '전체', onChange }: Props) {
  const scrollDirection = useScrollDirection();

  return (
    <S.CategoryContainer scrollDirection={scrollDirection}>
      {COMMUNITY_POST_CATEGORIES.map((category) => (
        <LoggingClick
          key={category}
          logName='hh_community_click'
          data={{ section: '카테고리', clicked_category: category }}
        >
          <Category
            onClick={() => onChange?.(category)}
            active={value === category}
          >
            {category}
          </Category>
        </LoggingClick>
      ))}
    </S.CategoryContainer>
  );
}
