import { z } from 'zod';

export const NICKNAME_MIN_LENGTH = 2;
export const NICKNAME_MAX_LENGTH = 8;
// 한글, 영문, 숫자만 입력 가능하고 나머지는 제거하기(공백, 이모지도 제거)
export const NICKNAME_REGEX = /[^\w\sㄱ-ㅎㅏ-ㅣ가-힣]|[_]|[\s]/g;

export const profileSchema = z.object({
  nickname: z.string().min(2).max(8).optional(),
  image: z.instanceof(File).optional(),
});

export type ProfileSchema = z.infer<typeof profileSchema>;
