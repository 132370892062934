import {
  Flex,
  NavigationMore,
  neutralDay,
  ThemeProvider,
} from '@teamsparta/design-system';
import { useBoolean, When } from '@teamsparta/react';

import { useIsDesktop } from '@/hooks';
import { Dialog, Popover } from '@/pages/community/shared';
import { breakpoints } from '@/styles/themes';

import { DeleteButton, EditButton } from './components';

interface Props {
  deleteTitle?: string;
  deleteDescription?: string;
  isDeletePending: boolean;
  onDelete: () => void;
  onEdit: () => void;
}

export function Actions({
  deleteTitle,
  deleteDescription,
  isDeletePending,
  onDelete,
  onEdit,
}: Props) {
  const isDesktop = useIsDesktop({ breakpoint: breakpoints.largeTablet });

  const {
    value: dialogOpen,
    setTrue: openDialog,
    setFalse: closeDialog,
  } = useBoolean();
  const {
    value: popoverOpen,
    setTrue: openPopover,
    setFalse: closePopover,
  } = useBoolean();

  function handleEdit() {
    onEdit();
    closeDialog();
  }

  function handleDelete() {
    onDelete();
    closeDialog();
  }

  return (
    <When
      condition={isDesktop}
      fallback={
        <Dialog.Root
          open={dialogOpen}
          onOpenChange={(nextOpen) => (nextOpen ? openDialog() : closeDialog())}
        >
          <Dialog.Trigger>
            <NavigationMore size={16} color={neutralDay.gray50} />
          </Dialog.Trigger>
          <Dialog.Portal>
            <ThemeProvider mode='dark'>
              <Dialog.Overlay />
              <Dialog.Content>
                <Flex.Column gap={8} padding={24} fullWidth>
                  <EditButton onEdit={handleEdit} />
                  <DeleteButton
                    title={deleteTitle}
                    description={deleteDescription}
                    isPending={isDeletePending}
                    onDelete={handleDelete}
                  />
                </Flex.Column>
              </Dialog.Content>
            </ThemeProvider>
          </Dialog.Portal>
        </Dialog.Root>
      }
    >
      <Popover.Root
        open={popoverOpen}
        onOpenChange={(nextOpen) => (nextOpen ? openPopover() : closePopover())}
      >
        <Popover.Trigger>
          <NavigationMore size={16} color={neutralDay.gray50} />
        </Popover.Trigger>
        <Popover.Portal>
          <ThemeProvider mode='dark'>
            <Popover.Content align='end'>
              <Flex.Column>
                <EditButton onEdit={handleEdit} />
                <DeleteButton
                  title={deleteTitle}
                  description={deleteDescription}
                  isPending={isDeletePending}
                  onDelete={handleDelete}
                />
              </Flex.Column>
            </Popover.Content>
          </ThemeProvider>
        </Popover.Portal>
      </Popover.Root>
    </When>
  );
}
