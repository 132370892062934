import { useQuery } from '@tanstack/react-query';
import {
  ArrowChevronDown,
  ArrowChevronRightSmall,
  ContentsNote,
  Flex,
  NavigationSetting,
  neutralDay,
  Text,
} from '@teamsparta/design-system';
import { Separated, When } from '@teamsparta/react';
import { isNotNil } from '@teamsparta/utils';
import { useAtom } from 'jotai';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, type ReactNode, useRef, useState } from 'react';

import WaveIcon from '/public/images/icons/waveIcon.svg';
import { LoggingClick } from '@/components/common/LoggingClick';
import Only from '@/components/common/Only';
import { Suspense } from '@/components/common/Suspense';
import SwitchCase from '@/components/common/SwitchCase';
import { SHORT_TERM_SKILL_UP } from '@/constants';
import type {
  GnbMenuGroup,
  GnbMenuGroupLink,
  GnbMenuItem,
  GnbMenuSubGroup,
} from '@/constants/gnb';
import {
  BADGE_TEXT_MAP,
  isGroup,
  isGroupLink,
  isItem,
  isSubGroup,
  MobileGnbDropdownMenuList,
  MobileGnbMenuList,
} from '@/constants/gnb';
import { useOnlineUser, useToken } from '@/hooks';
import { useLockBodyScroll } from '@/hooks/common';
import { useRecruitingStatusByCourse } from '@/hooks/react-query/common/round-status';
import { profileQuery } from '@/hooks/react-query/community/profile';
import { useScrollDirection } from '@/lib/utils/businessLogics/_common/useScrollDirection';
import { logout } from '@/lib/utils/businessLogics/login/login';
import { env } from '@/lib/utils/env';
import { isClient } from '@/lib/utils/helpers/enviroment';
import { MobileGNBOpenAtom } from '@/store/landing';
import { useLoginModal } from '@/store/login';

import { getCourseBadgeText, isDisabledCourse } from '../logic';
import { MobileGNBHeaderForCommunity } from './components';
import * as S from './style';

export default function MobileGnb() {
  const [isOpen, setIsOpen] = useAtom(MobileGNBOpenAtom);
  const userInfo = useOnlineUser();
  const { token } = useToken();

  const isSignIn = !!userInfo;

  function toggleGnb() {
    setIsOpen(!isOpen);
  }

  useLockBodyScroll(isOpen);

  const router = useRouter();
  const isCommunity = router.pathname.includes('/community');
  const { data: profile } = useQuery({ ...profileQuery, enabled: isCommunity });

  return (
    <>
      <LoggingClick logName='hh_hub_menuHamburger_click'>
        <HamburgerMenu isOpen={isOpen} onClick={toggleGnb} />
      </LoggingClick>
      <S.MobileGnbOverlay isOpen={isOpen}>
        <When
          condition={isCommunity && isNotNil(profile) && Boolean(token)}
          fallback={
            <MobileGnbHeader isSignIn={isSignIn} name={userInfo?.name} />
          }
        >
          <Suspense clientOnly>
            <MobileGNBHeaderForCommunity profile={profile} />
          </Suspense>
        </When>
        <MobileIconButtons isSignIn={isSignIn} />
        <MobileMenuTabs tabGroups={MobileGnbDropdownMenuList} />
      </S.MobileGnbOverlay>
    </>
  );
}

interface MobileGnbHeaderProps {
  isSignIn: boolean;
  name?: string;
}

function MobileGnbHeader({ isSignIn, name }: MobileGnbHeaderProps) {
  const loginModal = useLoginModal();
  const router = useRouter();

  function handleClickHeader() {
    if (isSignIn) {
      return;
    }
    loginModal.open(router.asPath);
  }

  return (
    <S.MobileGnbHeader isSignIn={isSignIn} onClick={handleClickHeader}>
      {isSignIn ? (
        <>
          <Text as='h2' mFont='mTitle1' color='neutralDay.white'>
            {name} 님, 안녕하세요.
          </Text>
          <Flex align='center'>
            <Text
              as='span'
              mFont='mCaption2'
              color='neutralDay.gray60'
              onClick={logout}
            >
              로그아웃
            </Text>
          </Flex>
        </>
      ) : (
        <Flex align='center' gap={6}>
          <Text
            as='span'
            mFont='mTitle1'
            color='neutralDay.white'
            data-testid='mobileLoginBtn'
          >
            로그인 하기
          </Text>
          <ArrowChevronRightSmall size={20} color={neutralDay.gray0} />
        </Flex>
      )}
    </S.MobileGnbHeader>
  );
}

const iconMap = {
  '나의 수강': (
    <Image src={WaveIcon} width={16} height={16} alt='웨이브 아이콘' />
  ),
  '결제 내역': <ContentsNote size={16} style='line' color='#ffffff' />,
  '계정 정보': <NavigationSetting size={16} style='line' color='#ffffff' />,
};

function MobileIconButtons({ isSignIn }: { isSignIn: boolean }) {
  const iconButtonList = MobileGnbMenuList.filter(
    (item) => isItem(item) && !item.mobileHidden,
  ) as GnbMenuItem[];

  return (
    <Only condition={isSignIn === true}>
      <S.MobileGnbIconButtonContainer>
        <Separated with={<S.MobileGnbIconButtonDivider />}>
          {iconButtonList.map((item) => (
            <Fragment key={item.title}>
              <LoggingClick
                logName='hh_gnb_click'
                data={{
                  button_text: item.title,
                  button_href: `${env.NEXT_PUBLIC_PAGE_URL}${item.link}`,
                  page_title: isClient() ? document.title : '',
                }}
              >
                <S.MobileGnbIconButton
                  href={item.link}
                  target={item.newTab ? '_blank' : ''}
                >
                  {iconMap[item.title]}
                  <Text as='span' mFont='mBody4' color={neutralDay.gray0}>
                    {item.title}
                  </Text>
                </S.MobileGnbIconButton>
              </LoggingClick>
            </Fragment>
          ))}
        </Separated>
      </S.MobileGnbIconButtonContainer>
    </Only>
  );
}

interface MobileMenuTabsProps {
  tabGroups: Array<GnbMenuGroup | GnbMenuGroupLink>;
}

function MobileMenuTabs({ tabGroups }: MobileMenuTabsProps) {
  const router = useRouter();
  const [selectedTabs, setSelectedTabs] = useState<string | null>(null);
  const mobileGnbTabContentRef = useRef<HTMLDivElement>(null);

  const selectedGroup = tabGroups.find(
    (group) => group.title === selectedTabs,
  ) as GnbMenuGroup | undefined;

  const subGroups =
    selectedGroup && isGroup(selectedGroup) ? selectedGroup.children : [];

  function handleClickTab(group: GnbMenuGroup | GnbMenuGroupLink) {
    return () => {
      if (isGroupLink(group)) {
        handleGroupLink(group);
      } else {
        setSelectedTabs((prevSelectedTabs) =>
          prevSelectedTabs === group.title ? null : group.title,
        );
      }
    };
  }

  function handleGroupLink(group: GnbMenuGroupLink) {
    if (group.newTab) {
      window.open(group.link, '_blank');
    } else {
      router.push(group.link);
    }
  }

  return (
    <S.MobileGnbTabContainer>
      <Flex.Column justify='start' gap={4}>
        {tabGroups.map((group) => (
          <S.MobileGnbTabTrigger
            key={group.title}
            onClick={handleClickTab(group)}
          >
            {group.type === 'groupLink' ? (
              <LoggingClick
                logName='hh_gnb_click'
                data={{
                  button_text: group.title,
                  button_href: group.link,
                  page_title: isClient() ? document.title : '',
                }}
              >
                <S.MenuTitleContainer>
                  <S.MenuTitle>
                    <Text as='span' mFont='mTitle2' color='neutralDay.white'>
                      {group.title}
                    </Text>
                    {group.isNew && <S.RedDot />}
                  </S.MenuTitle>
                </S.MenuTitleContainer>
              </LoggingClick>
            ) : (
              <S.BootcampMenuContainer>
                <S.MenuTitleContainer>
                  <S.MenuTitle>
                    <Text as='span' mFont='mTitle2' color='neutralDay.white'>
                      {group.title}
                    </Text>
                    {group.isNew && <S.RedDot />}
                  </S.MenuTitle>
                  <S.ArrowChevronDownWrapper
                    isRotated={group.title === selectedGroup?.title}
                  >
                    <ArrowChevronDown color={neutralDay.gray60} size={20} />
                  </S.ArrowChevronDownWrapper>
                </S.MenuTitleContainer>
                <S.MobileGnbTabContent
                  ref={mobileGnbTabContentRef}
                  isOpen={group.title === selectedGroup?.title}
                  isStudy={group.title === '스터디'}
                >
                  {subGroups.map((subGroup: GnbMenuSubGroup | GnbMenuItem) => (
                    <SwitchCase
                      key={subGroup.title}
                      cases={subGroup.type}
                      caseBy={{
                        subGroup: isSubGroup(subGroup) && (
                          <>
                            <Flex.Column
                              key={subGroup.title}
                              style={{ flexGrow: '1' }}
                              gap={4}
                            >
                              <Text
                                as='span'
                                mFont='mCaption2'
                                color='neutralDay.gray70'
                              >
                                {subGroup.title}
                              </Text>
                              {subGroup.menus?.map((item) => (
                                <GnbSubGroupItem key={item.title} {...item} />
                              ))}
                            </Flex.Column>
                          </>
                        ),
                        item: isItem(subGroup) && (
                          <>
                            <GnbSubGroupItem {...subGroup} />
                          </>
                        ),
                      }}
                    />
                  ))}
                </S.MobileGnbTabContent>
              </S.BootcampMenuContainer>
            )}
          </S.MobileGnbTabTrigger>
        ))}
      </Flex.Column>
    </S.MobileGnbTabContainer>
  );
}

interface GnbSubGroupItemProps extends GnbMenuItem {
  rightIcon?: ReactNode;
  badgeText?: string;
  disabled?: boolean;
}

function GnbSubGroupItem({
  link,
  title,
  newTab,
  disabled,
  courseType,
}: GnbSubGroupItemProps) {
  const router = useRouter();
  const isActive = router.asPath.includes(link);
  const { status } = useRecruitingStatusByCourse({
    course: courseType,
    enabled: !isDisabledCourse(courseType),
  });

  const badgeText = getCourseBadgeText(courseType, BADGE_TEXT_MAP[status]);

  const getButtonText = () => {
    if (courseType === SHORT_TERM_SKILL_UP) {
      return '단기 스킬업';
    }
    return title;
  };

  return (
    <LoggingClick
      logName='hh_gnb_click'
      data={{
        button_text: getButtonText(),
        button_href: link,
        page_title: isClient() ? document.title : '',
      }}
    >
      <Link
        key={title}
        href={disabled ? '' : link}
        target={newTab ? '_blank' : ''}
        passHref
        style={{ width: '100%', pointerEvents: disabled ? 'none' : 'auto' }}
      >
        <S.GnbSubGroupItem
          as='span'
          mFont={isActive ? 'mBody1' : 'mBody2'}
          color={isActive ? 'hanghae.red100' : 'neutralDay.white'}
          isActive={isActive}
          disabled={disabled}
        >
          {title}
          <S.RoundInfoBadge isNew badgeText={badgeText}>
            {badgeText}
          </S.RoundInfoBadge>
        </S.GnbSubGroupItem>
      </Link>
    </LoggingClick>
  );
}

interface HamburgerMenuProps {
  isOpen: boolean;
  onClick: () => void;
}

function HamburgerMenu({ isOpen, onClick }: HamburgerMenuProps) {
  const scrollDirection = useScrollDirection();

  return (
    <S.HamburgerButton
      onClick={onClick}
      scrollDirection={scrollDirection}
      data-testid='mobileGnbToggle'
    >
      <S.FirstStick isOpen={isOpen} />
      <S.SecondStick isOpen={isOpen} />
      <S.ThirdStick isOpen={isOpen} />
    </S.HamburgerButton>
  );
}
