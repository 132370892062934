/**
 * @todo 여기에 사용된 상수들, 함수들, 타입들을 어떻게 분리할지, 여기에 그대로 둘지 추후 시간이 흘러 조금 더 프로젝트가 성장하고 각자 더 나은 의견이 나오면 기준을 정할 필요가 있음.
 * @link https://github.com/TeamSparta-Inc/hh-v2-frontend/pull/1256
 */

import type {
  _99ClubCourseDetail,
  _99ClubCourseRoute,
  CareerCoachingDetail,
  CareerCoachingRoute,
  CourseDetail,
  CourseRoute,
  OldCourse,
  OldCourseDetail,
  OldCourseRoute,
  PlusCourseDetail,
  PlusCourseRoute,
  RebootCourseDetail,
  RebootCourseRoute,
} from '@/types';

export const FULL_TIME = 'HANGHAE';
export const PLUS_BACKEND = 'HANGHAE_PLUS_BACKEND';
export const PLUS_FRONTEND = 'HANGHAE_PLUS_FRONTEND';
export const PLUS_AI = 'HANGHAE_PLUS_AI';
export const BACKEND_CAREER_COACHING = 'HANGHAE_BACKEND_CAREER_COACHING';
export const FRONTEND_CAREER_COACHING = 'HANGHAE_FRONTEND_CAREER_COACHING';
export const REBOOT = 'HANGHAE_JOB_SUPPORT';
export const INNOVATION = 'INNOVATION';
export const SHORT_TERM_SKILL_UP = 'SHORT_TERM_SKILL_UP';

// DETAIL 이름?
export const PLUS_COURSE_DETAIL: Record<PlusCourseRoute, PlusCourseDetail> = {
  fe: {
    key: 'fe',
    type: PLUS_FRONTEND,
    koreanName: '플러스 프론트엔드 코스',
    koreanPosition: '프론트엔드',
    englishPosition: 'frontend',
    landingUrl: '/plus/fe',
    applyUrl: '/plus/apply/fe',
  },
  be: {
    key: 'be',
    type: PLUS_BACKEND,
    koreanName: '플러스 백엔드 코스',
    koreanPosition: '백엔드',
    englishPosition: 'backend',
    landingUrl: '/plus/be',
    applyUrl: '/plus/apply/be',
  },
  ai: {
    key: 'ai',
    type: PLUS_AI,
    koreanName: '플러스 AI 코스',
    koreanPosition: 'AI',
    englishPosition: 'ai',
    landingUrl: 'https://hhplus-ai.oopy.io/',
    applyUrl: '/plus/apply/ai',
  },
};

export const CAREER_COACHING_DETAIL: Record<
  CareerCoachingRoute,
  CareerCoachingDetail
> = {
  be: {
    key: 'be',
    type: BACKEND_CAREER_COACHING,
    koreanName: '백엔드 커리어 코칭',
    koreanPosition: '백엔드',
    englishPosition: 'backend',
    applyUrl: '/coaching/apply/be',
    landingUrl: 'https://hhplus-careercoaching.oopy.io/',
  },
  fe: {
    key: 'fe',
    type: FRONTEND_CAREER_COACHING,
    koreanName: '프론트엔드 커리어 코칭',
    koreanPosition: '프론트엔드',
    englishPosition: 'frontend',
    applyUrl: '/coaching/apply/fe',
    landingUrl: 'https://hhplus-careercoaching.oopy.io/',
  },
};

export const PLUS_CORUSE_KOREAN_NAMES = Object.values(PLUS_COURSE_DETAIL).map(
  (course) => course.koreanName,
);

export const _99CLUB_COURSE_DETAIL: Record<
  _99ClubCourseRoute,
  _99ClubCourseDetail
> = {
  'coding-test': {
    key: 'coding-test',
    type: '99CLUB_CODING_TEST',
    koreanName: '99클럽 코딩테스트 스터디',
    applyUrl: '/99club/apply/coding-test',
    landingUrl: '/99club-codingtest',
  },
};

export const REBOOT_COURSE_DETAIL: Record<
  RebootCourseRoute,
  RebootCourseDetail
> = {
  reboot: {
    key: 'reboot',
    type: 'reboot',
    koreanName: '취업 리부트 코스',
    applyUrl: '/reboot/apply',
    landingUrl: '/reboot',
  },
};

export const OLD_COURSE_DETAIL: Record<OldCourseRoute, OldCourseDetail> = {
  'full-time': {
    key: 'full-time',
    type: 'full-time',
    koreanName: '항해 풀타임',
  },
  'part-time': {
    key: 'part-time',
    type: 'part-time',
    koreanName: '항해 파트타임',
  },
  pm: {
    key: 'pm',
    type: 'pm',
    koreanName: '항해PM코스',
  },
  pre: {
    key: 'pre',
    type: 'pre',
    koreanName: '항해 프리코스',
  },
};

/**
 * 코스 상세정보를 가져오는 함수에서 사용되는 상수
 * @deprecated 각 코스 단일 detail 상수를 사용해주세요.
 * @example
 * ```ts
 * const plusBeDetail = PLUS_COURSE_DETAIL.be;
 * const rebootDetail = getCourseDetail('reboot');
 * ```
 */
export const COURSE_DETAIL: Record<
  CourseRoute | 'be-career-coaching' | 'fe-career-coaching',
  CourseDetail
> = {
  ...PLUS_COURSE_DETAIL,
  ..._99CLUB_COURSE_DETAIL,
  reboot: {
    key: 'reboot',
    type: REBOOT,
    koreanName: '취업 리부트 코스',
    applyUrl: '/reboot/apply',
  },
  innovation: {
    key: 'innovation',
    type: INNOVATION,
    koreanName: '24이노캠',
  },
  // FIXME: 백엔드 커리어 코칭의 키와 플러스 백엔드의 키가 같아서 충돌이 발생함.
  'be-career-coaching': CAREER_COACHING_DETAIL.be,
  'fe-career-coaching': CAREER_COACHING_DETAIL.fe,
};

type ValueOf<T> = T[keyof T];

type CourseKey = keyof typeof COURSE_DETAIL;
type CourseDetailValue = ValueOf<(typeof COURSE_DETAIL)[CourseKey]>;

/**
 * @deprecated 각 코스 단일 detail 상수를 사용해주세요.
 * FIXME: courseDetail의 여러가지 값들이 코스마다 서로 겹쳐 원하는 값이 나오지 않음
 */
export function getCourseDetail(
  value: CourseDetailValue | CourseKey,
): CourseDetail | undefined {
  if (COURSE_DETAIL[value]) {
    return COURSE_DETAIL[value];
  }

  return Object.values(COURSE_DETAIL).find((course) =>
    Object.values(course).includes(value as CourseDetailValue),
  );
}

export function isOldCourse(course: string): course is OldCourse {
  return Object.values(OLD_COURSE_DETAIL).some(
    (oldCourse) => oldCourse.koreanName === course,
  );
}

export function isPlusBE(value: CourseDetailValue | CourseKey) {
  return getCourseDetail(value)?.koreanName === '플러스 백엔드 코스';
}

export function isPlusFE(value: CourseDetailValue | CourseKey) {
  return getCourseDetail(value)?.koreanName === '플러스 프론트엔드 코스';
}

export function isPlusAI(value: CourseDetailValue | CourseKey) {
  return getCourseDetail(value)?.koreanName === '플러스 AI 코스';
}

export function isPlus(value: CourseDetailValue | CourseKey) {
  return Object.values(PLUS_COURSE_DETAIL).some(
    (plusCourse) =>
      plusCourse.koreanName === getCourseDetail(value)?.koreanName,
  );
}

export function isCareerCoaching(value: string) {
  return Object.values(CAREER_COACHING_DETAIL).some(
    (careerCoaching) => careerCoaching.koreanName === value,
  );
}

export function is99Club(value: CourseDetailValue | CourseKey) {
  return Object.values(_99CLUB_COURSE_DETAIL).some(
    (_99ClubCourse) =>
      _99ClubCourse.koreanName === getCourseDetail(value)?.koreanName,
  );
}

export function isReboot(value: CourseDetailValue | CourseKey) {
  return getCourseDetail(value)?.koreanName === '취업 리부트 코스';
}
