import styled from '@emotion/styled';
import { neutralDay } from '@teamsparta/design-system';

export const Dot = styled.span<{
  width?: number;
  height?: number;
  backgroundColor?: string;
}>`
  width: ${({ width }) => width ?? 2}px;
  height: ${({ height }) => height ?? 2}px;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? neutralDay.gray70};
`;
