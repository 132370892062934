import { isNotNil } from '@teamsparta/utils';

import type { Poll } from '@/api/community/post';
import { DAY, HOUR, MINUTE } from '@/constants/time';
import { useToken } from '@/hooks/user/useToken';
import { adjustDateByPurpose, isAfter } from '@/lib/utils/date';

export function formatRemainingTimeToText(deadline: Date) {
  const now = new Date();
  const diffInMilliseconds = deadline.getTime() - now.getTime();

  if (diffInMilliseconds <= 0) {
    return '투표 마감';
  }

  const diffInMinutes = Math.floor(diffInMilliseconds / MINUTE);
  const diffInHours = Math.floor(diffInMilliseconds / HOUR);
  const diffInDays = Math.floor(diffInMilliseconds / DAY);

  if (diffInMinutes < 60) {
    return `${diffInMinutes}분 남음`;
  }

  if (diffInHours < 24) {
    return `${diffInHours}시간 남음`;
  }

  return `${diffInDays}일 남음`;
}

/**
 * @todo community 리팩토링 생각해보기
 */
type PollComponentType =
  | 'BluredPoll'
  | 'DidNotPoll'
  | 'DidPoll'
  | 'Loading'
  | 'None';

export function usePoll(poll: Poll | null): {
  pollComponentType: PollComponentType;
} {
  const { isLoading, token } = useToken();

  if (isLoading) {
    return {
      pollComponentType: 'Loading',
    };
  }

  const hasPoll = isNotNil(poll);
  const hasToken = isNotNil(token);
  function getPollComponentType(): PollComponentType {
    if (!hasPoll) {
      return 'None';
    }

    const isAfterPollEndDate = isAfter(
      new Date(),
      adjustDateByPurpose(poll.pollEndDate, 'calc'),
    );

    if (!hasToken) {
      return isAfterPollEndDate ? 'BluredPoll' : 'DidNotPoll';
    }

    if (isAfterPollEndDate || poll.didPoll) {
      return 'DidPoll';
    }

    return 'DidNotPoll';
  }

  return {
    pollComponentType: getPollComponentType(),
  };
}
