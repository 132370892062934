import type { PartialRecord } from '@teamsparta/types';
import { type ReactElement } from 'react';

interface SwitchCaseProps<T extends string | number> {
  cases: T;
  caseBy: PartialRecord<T, ReactElement>;
  defaultCase?: ReactElement;
}

export default function SwitchCase<T extends string | number>({
  cases,
  defaultCase,
  caseBy,
}: SwitchCaseProps<T>) {
  return caseBy[cases] ?? defaultCase ?? null;
}
