import DOMPurify from 'isomorphic-dompurify';

/**
 * @description html을 text로 변환합니다.
 */
export function htmlToText(html: string) {
  return (
    html
      // HTML 태그 제거
      .replace(/<[^>]*>/g, '')
      // HTML 엔티티 디코딩
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      // 연속된 공백 제거
      .replace(/\s+/g, ' ')
      .trim()
  );
}

/**
 * @description text를 maxLength만큼 잘라냅니다.
 */
export function truncateText({
  text,
  maxLength,
  withEllipsis = true,
}: {
  text: string;
  maxLength: number;
  withEllipsis?: boolean;
}) {
  if (text.length <= maxLength) {
    return text;
  }

  return `${text.slice(0, maxLength)}${withEllipsis ? '...' : ''}`;
}

/**
 * @description HTML에서 잠재적으로 위험한 문자열을 제거합니다.
 * XSS(Cross-Site Scripting) 공격을 방지하기 위해 악성 스크립트와 위험한 HTML 속성을 제거합니다.
 *
 * @example
 * const dirty = '<div onclick="alert()">Hello</div><script>alert("xss")</script>';
 * const clean = sanitizeHtml(dirty);
 * 결과: '<div>Hello</div>'
 *
 * @see https://www.npmjs.com/package/isomorphic-dompurify
 */
export function sanitizeHtml(html: string) {
  return DOMPurify.sanitize(html);
}
