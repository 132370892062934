import {
  Button,
  Flex,
  hanghae,
  neutralDay,
  Text,
} from '@teamsparta/design-system';
import type { MouseEvent } from 'react';

import { useLoginModal } from '@/store/login';

import * as S from './style';

interface Props {
  postId: string;
}

export function BluredPoll({ postId }: Props) {
  const { open, setNextPage } = useLoginModal();

  function handleClick(event: MouseEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();

    open();
    setNextPage(`/community/post/${postId}`);
  }

  return (
    <Flex.Column
      fullWidth
      style={{
        cursor: 'pointer',
        borderRadius: '8px',
        border: `1px solid ${neutralDay.gray90}`,
        position: 'relative',
        overflow: 'hidden',
      }}
      onClick={handleClick}
    >
      <Flex.Column
        fullWidth
        gap={6}
        style={{
          filter: 'blur(6px)',
        }}
      >
        {['선택지 1', '선택지 2'].map((optionText, index) => (
          <Flex.Row
            key={`${optionText}-${index}`}
            fullWidth
            justify='between'
            padding='10px 12px'
            style={{
              position: 'relative',
              overflow: 'hidden',
              border: `1px solid ${
                index === 0 ? 'rgba(255, 92, 92, 0.40)' : neutralDay.gray90
              }`,
              borderRadius: '999px',
            }}
          >
            <Text
              as='span'
              font='mBody3'
              color={neutralDay.gray30}
              style={{ zIndex: 1 }}
            >
              {optionText}
            </Text>
            <Text
              as='span'
              font='mBody3'
              color={index === 0 ? hanghae.red60 : neutralDay.gray60}
              style={{ zIndex: 1 }}
            >
              {50}% ({50})
            </Text>
            <S.PollProgressBar
              percentage={50}
              isMostSelectedOption={index === 0}
            />
          </Flex.Row>
        ))}
      </Flex.Column>

      <Button
        size='sm'
        radius='rounded'
        colorScheme='secondary'
        style={{
          width: '100px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        결과 보기
      </Button>
    </Flex.Column>
  );
}
