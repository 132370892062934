import axios, {
  AxiosError,
  type AxiosInstance as AxiosInstanceType,
  type AxiosRequestConfig,
} from 'axios';
import { deleteCookie, getCookie } from 'cookies-next';
import type { GetServerSidePropsContext } from 'next';

import {
  ApiException,
  CustomException,
  ForbiddenException,
  NetworkException,
  RefreshTokenExpiredException,
  RefreshTokenInvalidException,
  RefreshTokenRevokedException,
  StatusCode,
  TimeoutException,
  UnauthorizedException,
} from '@/lib/exceptions';
import { authStore, onlineUserInfoAtom, tokenAtom } from '@/store/user';
import type { ApiResponseV2 } from '@/types/common/api';

import { refresh } from './auth';

interface AxiosInstance extends AxiosInstanceType {
  request<T = any>(config: AxiosRequestConfig): Promise<T>;
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T>;
  put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T>;
  patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<T>;
}

/**
 * @description token 없이 요청하는 api를 위한 인스턴스
 */
export const instanceV2 = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SERVER,
}) as AxiosInstance;

instanceV2.interceptors.response.use(
  (response) => {
    const apiResponse: ApiResponseV2<unknown> = response.data;

    if (apiResponse.success === true) {
      return apiResponse.data;
    } else {
      throw new ApiException(
        apiResponse.errorMessage || '알 수 없는 API 오류',
        response.status,
      );
    }
  },
  (error: AxiosError) => {
    if (error.code === 'ECONNABORTED') {
      throw new TimeoutException(
        '요청 시간이 초과되었습니다',
        error.response?.status,
      );
    } else if (error.code === 'ERR_NETWORK' || error.request) {
      throw new NetworkException('네트워크 오류가 발생했습니다');
    } else if (error.response) {
      handleErrorResponse(error);
    } else {
      throw new CustomException('예기치 않은 오류가 발생했습니다');
    }
  },
);

function handleErrorResponse(error: AxiosError) {
  const apiResponse = error.response?.data as ApiResponseV2<unknown>;
  const status = error.response?.status;

  switch (status) {
    case 401:
      throw new UnauthorizedException(
        '인증되지 않은 접근 - 로그인 해주세요',
        status,
      );
    case 403:
      throw new ForbiddenException(
        '접근 금지 - 이 리소스에 접근할 권한이 없습니다',
        status,
      );
    case 502:
      throw new CustomException(
        '서버 게이트웨이 오류 - 잠시 후 다시 시도해주세요',
        status,
      );
    default:
      throw new ApiException(
        (apiResponse?.success === false && apiResponse.errorMessage) ||
          '알 수 없는 API 오류',
        status,
      );
  }
}

// let requestRefreshPromise: Promise<string | void> | null = null;

/**
 * @description token이 필요한 api를 요청하기 위한 인스턴스(클라이언트용)
 */
export const instanceWithAuth = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SERVER,
}) as AxiosInstance;

/**
 * @todo community 여기 정말 박살남..!
 */
instanceWithAuth.interceptors.request.use(async (config) => {
  if (!config.headers) {
    return config;
  }

  const accessToken = getCookie('token');
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

let refreshPromise: Promise<string | void> | null = null;
let refreshRetryCount = 0;
const MAX_REFRESH_RETRY_COUNT = 10;

instanceWithAuth.interceptors.response.use(
  (response) => {
    refreshRetryCount = 0;
    const apiResponse: ApiResponseV2<unknown> = response.data;

    if (apiResponse.success === true) {
      return apiResponse.data;
    } else {
      throw new ApiException(
        apiResponse.errorMessage || '알 수 없는 API 오류',
        response.status,
      );
    }
  },
  async (error: AxiosError) => {
    switch (error.response?.status) {
      case StatusCode.Unauthorized:
        if (refreshRetryCount >= MAX_REFRESH_RETRY_COUNT) {
          refreshRetryCount = 0;
          throw error;
        }

        try {
          if (!refreshPromise) {
            const refreshToken = getCookie('refresh');
            refreshPromise = refresh(refreshToken ?? '')
              .then((newAccessToken) => {
                authStore.set(tokenAtom, {
                  isLoading: false,
                  token: newAccessToken,
                });

                return newAccessToken;
              })
              .finally(() => {
                refreshPromise = null;
              });
          }

          await refreshPromise;
          refreshRetryCount++;
          return instanceWithAuth.request(error.config);
        } catch (refreshError) {
          if (!(refreshError instanceof AxiosError)) {
            throw refreshError;
          }

          const domain = document.location.origin.includes(
            '.spartacodingclub.kr',
          )
            ? '.spartacodingclub.kr'
            : document.location.hostname;
          const sameSite = document.location.origin.includes(
            '.spartacodingclub.kr',
          )
            ? 'none'
            : undefined;
          deleteCookie('token', { path: '/', domain, secure: true, sameSite });
          deleteCookie('refresh', {
            path: '/',
            domain,
            secure: true,
            sameSite,
          });
          deleteCookie('userinfo', {
            path: '/',
            domain,
            secure: true,
            sameSite,
          });
          authStore.set(onlineUserInfoAtom, null);
          authStore.set(tokenAtom, { isLoading: false, token: null });
          /**
           * @todo community refresh에서 에러가 발생하면 online으로 logout을 요청해 token을 제거
           * 이후 get 요청들 다시 헤야 될 듯
           */

          switch (refreshError.response?.status) {
            case StatusCode.RefreshTokenExpired:
              throw new RefreshTokenExpiredException(
                '만료된 리프레시 토큰',
                refreshError.response?.status,
              );
            case StatusCode.RefreshTokenInvalid:
              throw new RefreshTokenInvalidException(
                '유효하지 않은 리프레시 토큰',
                refreshError.response?.status,
              );
            case StatusCode.RefreshTokenRevoked:
              throw new RefreshTokenRevokedException(
                '삭제된 리프레시 토큰',
                refreshError.response?.status,
              );
            default:
              throw refreshError;
          }
        }
      default:
        throw error;
    }
  },
);

/**
 * @description 서버 사이드에서 인증 필요한 api를 요청하는 인스턴스를 만들기 위한 함수
 * server side에서는 매번 만들어줘야 함.
 * @todo community instanceWithAuth와 함께 중복되는 부분 분리 필요
 */
export function createServerSideInstanceWithAuth({
  context,
}: {
  context: GetServerSidePropsContext;
}) {
  let refreshPromise: Promise<string | void> | null = null;
  let refreshRetryCount = 0;

  const { req: nextRequest, res: nextResponse } = context;
  const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_SERVER,
  }) as AxiosInstance;

  instance.interceptors.request.use((config) => {
    if (!config.headers) {
      return config;
    }

    const accessToken = getCookie('token', { req: nextRequest });

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      refreshRetryCount = 0;
      const apiResponse: ApiResponseV2<unknown> = response.data;

      if (apiResponse.success === true) {
        return apiResponse.data;
      } else {
        throw new ApiException(
          apiResponse.errorMessage || '알 수 없는 API 오류',
          response.status,
        );
      }
    },
    async (error: AxiosError) => {
      switch (error.response?.status) {
        case 401:
          if (refreshRetryCount >= MAX_REFRESH_RETRY_COUNT) {
            refreshRetryCount = 0;
            throw error;
          }

          try {
            if (!refreshPromise) {
              const refreshToken = getCookie('refresh', { req: nextRequest });
              refreshPromise = refresh(refreshToken ?? '')
                .then((token) => {
                  nextResponse.setHeader('Set-Cookie', `token=${token}`);

                  nextRequest.cookies.token = token;
                })
                .finally(() => {
                  refreshPromise = null;
                });
            }

            await refreshPromise;
            refreshRetryCount++;
            return instance.request(error.config);
          } catch (error) {
            nextResponse.setHeader('Set-Cookie', [
              'token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT',
              'refresh=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT',
              'userinfo=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT',
            ]);
            throw error;
          }
        default:
          /**
           * @todo community 자세한 예외 처리 추가 필요
           */
          throw error;
      }
    },
  );

  return instance;
}
