import { Flex, InfoTimer, neutralDay, Text } from '@teamsparta/design-system';
import { When } from '@teamsparta/react';

import { Dot } from '@/components/common/Dot';
import { adjustDateByPurpose } from '@/lib/utils/date';
import { formatRemainingTimeToText } from '@/pages/community/logic';

interface Props {
  pollEndDate: string;
  totalSelectedCount: number;
}

export function PollMetadata({ pollEndDate, totalSelectedCount }: Props) {
  const timeRemainingText = formatRemainingTimeToText(
    adjustDateByPurpose(pollEndDate, 'display'),
  );

  return (
    <Flex.Row gap={6} align='center'>
      <Flex.Row gap={2} align='center'>
        <When condition={timeRemainingText !== '투표 마감'}>
          <InfoTimer size={16} color={neutralDay.gray70} style='line' />
        </When>
        <Text as='span' font='wCaption1' color={neutralDay.gray70}>
          {timeRemainingText}
        </Text>
      </Flex.Row>
      <Dot />
      <Text as='span' font='wCaption1' color={neutralDay.gray70}>
        {totalSelectedCount}명 참여
      </Text>
    </Flex.Row>
  );
}
