import styled from '@emotion/styled';
import { isNumber } from '@teamsparta/utils';

/**
 * @todo 이거 @teamsparta/stack이나 @teamsparta/react에 넣는 건 어떨지
 */
export const Spacer = styled.div<{
  orientation: 'vertical' | 'horizontal';
  size: number;
}>`
  ${({ orientation = 'vertical', size = '1rem' }) => {
    const _size = isNumber(size) ? `${size}px` : size;

    return {
      display: 'flex',
      ...(orientation === 'horizontal'
        ? {
            width: _size,
            minWidth: _size,
            height: 'auto',
          }
        : {
            width: 'auto',
            height: _size,
            minHeight: _size,
          }),
    };
  }}
`;
