import { cloneElement, type ReactElement } from 'react';

import { sendLog } from '@/lib/utils/send-log';

export function LoggingClick({
  children,
  logName,
  data,
  disabled = false,
}: {
  children: ReactElement;
  logName: string;
  data?: Record<string | number, any>;
  disabled?: boolean;
}) {
  return cloneElement(children, {
    onClick: (e) => {
      if (!disabled) {
        sendLog(logName, data);
      }

      children?.props?.onClick?.(e);
    },
  });
}
