/**
 * 파일 위치도 확인해 주세요!
 */

export enum StatusCode {
  Unauthorized = 401, // 항해 백엔드에서 던지는 401
  Forbidden = 403,
  NotFound = 404,
  Timeout = 408,
  /**
   * @description refresh token 관련 http 에러 상태 코드
   * 여기서 478과 479는 비표준 http status code로 회사에서 자체적으로 정의해서 사용하는 custom status code.
   * online server에서 refresh token 형식이 이상할 때를 위해 특수한 경우로 사용하는 status code
   */
  RefreshTokenExpired = 401, // online-backend에서 던지는 401
  RefreshTokenInvalid = 478,
  RefreshTokenRevoked = 479,
  InternalServerError = 500,
  BadGateway = 502,
}

class BaseException extends Error {
  timestamp: string;
  statusCode: StatusCode;

  constructor(message: string, statusCode: StatusCode) {
    super(message);
    this.name = this.constructor.name;
    this.timestamp = new Date().toISOString();
    this.statusCode = statusCode;
  }
}

export class CustomException extends BaseException {
  constructor(
    message: string,
    statusCode: StatusCode = StatusCode.InternalServerError,
  ) {
    super(message, statusCode);
  }
}

export class NetworkException extends CustomException {
  constructor(
    message: string,
    statusCode: StatusCode = StatusCode.InternalServerError,
  ) {
    super(message, statusCode);
  }
}

export class ApiException extends CustomException {
  constructor(message: string, statusCode: StatusCode = StatusCode.NotFound) {
    super(message, statusCode);
  }
}

export class TimeoutException extends CustomException {
  constructor(message: string, statusCode: StatusCode = StatusCode.Timeout) {
    super(message, statusCode);
  }
}

export class UnauthorizedException extends CustomException {
  constructor(
    message: string,
    statusCode: StatusCode = StatusCode.Unauthorized,
  ) {
    super(message, statusCode);
  }
}

export class ForbiddenException extends CustomException {
  constructor(message: string, statusCode: StatusCode = StatusCode.Forbidden) {
    super(message, statusCode);
  }
}

export class RefreshTokenExpiredException extends CustomException {
  constructor(
    message: string,
    statusCode: StatusCode = StatusCode.RefreshTokenExpired,
  ) {
    super(message, statusCode);
  }
}

export class RefreshTokenInvalidException extends CustomException {
  constructor(
    message: string,
    statusCode: StatusCode = StatusCode.RefreshTokenInvalid,
  ) {
    super(message, statusCode);
  }
}

export class RefreshTokenRevokedException extends CustomException {
  constructor(
    message: string,
    statusCode: StatusCode = StatusCode.RefreshTokenRevoked,
  ) {
    super(message, statusCode);
  }
}

export class NoneTokenException extends Error {}
