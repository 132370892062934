import { Flex, hanghae, neutralDay, Text } from '@teamsparta/design-system';

import type { PollOption } from '@/api/community/post';

import * as S from './style';

interface Props {
  pollOptions?: PollOption[];
}

/**
 * @todo community 로직을 어떻게 더 간결하게 만들지 고민해보기
 */
export function DidPoll({ pollOptions }: Props) {
  const totalSelectedCount =
    pollOptions?.reduce((acc, curr) => acc + curr.selectedCount, 0) ?? 0;
  const pollOptionsWithPercentage =
    pollOptions?.map((pollOption) => ({
      ...pollOption,
      percentage: (pollOption.selectedCount / (totalSelectedCount || 1)) * 100,
    })) ?? [];
  const mostSelectedOption = pollOptionsWithPercentage.reduce((acc, curr) =>
    acc.selectedCount > curr.selectedCount ? acc : curr,
  );
  const isAllSelectedCountSame = pollOptions?.every(
    (pollOption) =>
      pollOption.selectedCount === mostSelectedOption.selectedCount,
  );

  function isMostSelectedOption(optionText: string) {
    return (
      optionText === mostSelectedOption.optionText && !isAllSelectedCountSame
    );
  }

  return (
    <Flex.Column fullWidth gap={6}>
      {pollOptionsWithPercentage.map(
        ({ optionText, selectedCount, percentage }, index) => (
          <Flex.Row
            key={`${optionText}-${index}`}
            fullWidth
            justify='between'
            padding='10px 12px'
            style={{
              position: 'relative',
              overflow: 'hidden',
              border: `1px solid ${
                isMostSelectedOption(optionText)
                  ? 'rgba(255, 92, 92, 0.40)'
                  : neutralDay.gray90
              }`,
              borderRadius: '999px',
            }}
          >
            <Text
              as='span'
              font='mBody3'
              color={neutralDay.gray30}
              style={{ zIndex: 1 }}
            >
              {optionText}
            </Text>
            <Text
              as='span'
              font='mBody3'
              color={
                isMostSelectedOption(optionText)
                  ? hanghae.red60
                  : neutralDay.gray60
              }
              style={{ zIndex: 1 }}
            >
              {percentage.toFixed(1)}% ({selectedCount})
            </Text>
            <S.PollProgressBar
              percentage={percentage}
              isMostSelectedOption={isMostSelectedOption(optionText)}
            />
          </Flex.Row>
        ),
      )}
    </Flex.Column>
  );
}
