import type { CourseType } from '@/types';

/**
 * 코스별 특수 배지 텍스트를 반환하는 함수
 * - Docker 스터디: '알림 신청'
 * - 프론트엔드 커리어 코칭: '준비 중'
 * - 알고리즘 강의 스터디: '모집 중'
 * - 그 외 코스: 모집 상태에 따른 기본 배지 텍스트
 */
export function getCourseBadgeText(
  courseType: CourseType | undefined,
  defaultStatusBadgeText: string,
) {
  // @ts-ignore Docker 스터디는 항상 '알림 신청' 표시
  if (courseType === '99CLUB_DOCKER_STUDY') {
    return '알림 신청';
  }

  // 프론트엔드 커리어 코칭은 항상 '준비 중' 표시
  if (courseType === 'HANGHAE_FRONTEND_CAREER_COACHING') {
    return '준비 중';
  }

  // 알고리즘 강의 스터디는 항상 '모집 중' 표시
  if (courseType === '99CLUB_ALGORITHM_STUDY') {
    return '모집 중';
  }

  // 단기 스킬업 레디스를 활용한 대규모 트래픽 처리는 항상 '모집 중' 표시
  if (courseType === 'SHORT_TERM_SKILL_UP') {
    return '모집 중';
  }

  // 그 외 코스는 모집 상태에 따른 기본 배지 표시
  return defaultStatusBadgeText;
}

export function isDisabledCourse(courseType?: CourseType) {
  if (!courseType) {
    return false;
  }

  // 프론트엔드 커리어 코칭, 도커 스터디는 비활성화
  return (
    courseType === 'HANGHAE_FRONTEND_CAREER_COACHING' ||
    // @ts-ignore
    courseType === '99CLUB_DOCKER_STUDY'
  );
}
