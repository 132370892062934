import { noop } from '@teamsparta/utils';
import { useSyncExternalStore } from 'react';

const subscribe = () => noop;
const getSnapshot = () => true;
const getServerSnapshot = () => false;

export function useIsClient() {
  const isClient = useSyncExternalStore(
    subscribe,
    getSnapshot,
    getServerSnapshot,
  );

  return isClient;
}
