import styled from '@emotion/styled';
import { neutralDay } from '@teamsparta/design-system';

export const DidNotPollOption = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${neutralDay.gray80};
  border-radius: 999px;
  cursor: pointer;
  padding: 10px 12px;

  &:hover {
    background-color: ${neutralDay.gray95};
  }
`;
