import { queryOptions } from '@tanstack/react-query';

import { getProfile, patchProfile } from '@/api/community/profile';

import { useAuthMutation } from '../common/auth';

const profileQueryKey = {
  all: ['community-profile'] as const,
  patch: () => [...profileQueryKey.all, 'patch'] as const,
};

export const profileQuery = queryOptions({
  queryKey: profileQueryKey.all,
  queryFn: getProfile,
});

export function usePatchProfile() {
  return useAuthMutation({
    mutationKey: profileQueryKey.patch(),
    mutationFn: patchProfile,
  });
}
