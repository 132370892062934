import type { FlexProps } from '@teamsparta/design-system';
import { Flex, neutralDay, ThemeProvider } from '@teamsparta/design-system';

import GNB from '@/components/gnb/GNB';

import * as S from './style';

interface Props extends FlexProps<'div'> {}

export function CommunityLayout({ style, children, ...props }: Props) {
  return (
    <Flex.Column
      fullWidth
      style={{
        backgroundColor: neutralDay.gray100,
        minHeight: '100vh',
        ...style,
      }}
      padding='40px 0 0'
      {...props}
    >
      <GNB />
      <Flex
        fullWidth
        padding={{ mobile: '40px 0 0', desktop: '60px 0 167px' }}
        justify='center'
      >
        <S.ChildrenWrapper>
          <ThemeProvider mode='dark'>{children}</ThemeProvider>
        </S.ChildrenWrapper>
      </Flex>
    </Flex.Column>
  );
}
