import { instanceWithAuth } from '@/api/instance';

import type {
  DeleteCommunityPostCommentRequestDto,
  GetCommunityPostCommentsRequestDto,
  GetCommunityPostCommentsResponseDto,
  PatchCommunityPostCommentRequestDto,
  PostCommunityPostCommentRequestDto,
  PostCommunityPostCommentResponseDto,
} from './comment.dto';

export function getCommunityPostComments(
  request: GetCommunityPostCommentsRequestDto,
) {
  return instanceWithAuth.get<GetCommunityPostCommentsResponseDto>(
    `/v3/comments/${request.postId}`,
    {
      params: {
        cursor: request.cursor,
        sortBy: request.sortBy ?? 'liked',
        limit: 10,
      },
    },
  );
}

export function postCommunityPostComment(
  request: PostCommunityPostCommentRequestDto,
) {
  return instanceWithAuth.post<PostCommunityPostCommentResponseDto>(
    '/v3/comments',
    request,
  );
}

export function deleteCommunityPostComment(
  request: DeleteCommunityPostCommentRequestDto,
) {
  return instanceWithAuth.delete(`/v3/comments/${request.commentId}`);
}

export function patchCommunityPostComment(
  request: PatchCommunityPostCommentRequestDto,
) {
  return instanceWithAuth.patch(`/v3/comments`, request);
}
