export const breakpoints = {
  mobile: 320,
  smallTablet: 600,
  tablet: 768,
  largeTablet: 820,
  desktop: 1024,
};

export const device = {
  mobile: `(min-width: ${breakpoints.mobile}px)`,
  smallTablet: `(min-width: ${breakpoints.smallTablet}px)`,
  tablet: `(min-width: ${breakpoints.tablet}px)`,
  desktop: `(min-width: ${breakpoints.desktop}px)`,
  largeTablet: `(min-width: ${breakpoints.largeTablet}px)`,
  lessThanDesktop: `(max-width: ${breakpoints.desktop - 1}px)`,
};

export const DESKTOP = `@media ${device.desktop}`;
export const LARGE_TABLET = `@media ${device.largeTablet}`;
export const TABLET = `@media ${device.tablet}`;
export const SMALL_TABLET = `@media ${device.smallTablet}`;
