import styled from '@emotion/styled';
import * as RadixPopover from '@radix-ui/react-popover';
import { neutralDay } from '@teamsparta/design-system';

export const PopoverContent = styled(RadixPopover.Content)`
  padding: 8px;
  border-radius: 8px;
  background-color: ${neutralDay.gray90};
  box-shadow: 0px 8px 24px 0px rgba(129, 137, 143, 0.12);
`;
