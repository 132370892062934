import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 60px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  gap: 32px;
  width: 180px;
  padding-bottom: 40px;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;
