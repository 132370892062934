import { useCallback, useEffect, useState } from 'react';

import { breakpoints } from '@/styles/themes';

import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

const DEFAULT_BREAKPOINT = breakpoints.desktop;

interface Props {
  breakpoint?: number;
}

export const useIsDesktop = ({
  breakpoint = DEFAULT_BREAKPOINT,
}: Props = {}) => {
  const [isDesktop, setIsDesktop] = useState(false);

  const handleWindowSizeChange = useCallback(() => {
    setIsDesktop(window.innerWidth > breakpoint);
  }, [breakpoint]);

  useIsomorphicLayoutEffect(() => {
    handleWindowSizeChange();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  return isDesktop;
};
