import { onlineInstance } from '../v2/instance';
import type {
  GetVerifyResponseDto,
  PostLogoutResponseDto,
  PostRefreshResponseDto,
} from './auth.dto';

export async function refresh(refreshToken: string) {
  const response = await onlineInstance.post<PostRefreshResponseDto>(
    '/v1/auth/refresh',
    undefined,
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
      withCredentials: true,
    },
  );

  return response.data.data.token;
}

export async function verify(accessToken: string) {
  const response = await onlineInstance.get<GetVerifyResponseDto>(
    '/v1/auth/verify',
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      withCredentials: true,
    },
  );

  return response.data;
}

export async function logout(accessToken: string) {
  const response = await onlineInstance.post<PostLogoutResponseDto>(
    '/v1/auth/logout',
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      withCredentials: true,
    },
  );

  return response.data;
}
