import styled from '@emotion/styled';
import * as Dialog from '@radix-ui/react-dialog';
import { neutralDay } from '@teamsparta/design-system';

import { LARGE_TABLET } from '@/styles/themes';

export const DialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;
  background-color: #141617;
  opacity: 0.6;
  z-index: 1000;
`;

export const DialogContent = styled(Dialog.Content)`
  background-color: ${neutralDay.gray95};
  box-shadow: 0px 20px 24px 0px rgba(20, 22, 23, 0.24);
  position: fixed;
  z-index: 1001;
  border-radius: 16px 16px 0 0;
  width: 100%;
  bottom: 0;

  ${LARGE_TABLET} {
    top: 50%;
    left: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    width: 460px;
    border-radius: 16px;
  }
`;
