import { ActionPlus, Flex, neutralDay } from '@teamsparta/design-system';

import Footer from '@/components/common/Footer';
import Only from '@/components/common/Only';
import { Spacer } from '@/components/common/Spacer';
import { Suspense } from '@/components/common/Suspense';

import {
  CommunityLayout,
  Feed,
  MainPost,
  Notices,
  OngoingPollPosts,
  Profile,
  WritePostButton,
} from './shared';
import * as S from './style';

export function CommunityTemplate() {
  return (
    <>
      <Only.Desktop>
        <CommunityLayout>
          <Flex.Row gap={60} style={{ position: 'relative' }}>
            <S.Container>
              <Suspense clientOnly fallback={<Profile.Skeleton />}>
                <Profile />
              </Suspense>
              <Suspense clientOnly fallback={<OngoingPollPosts.Skeleton />}>
                <OngoingPollPosts />
              </Suspense>
              <Suspense clientOnly fallback={<Notices.Skeleton />}>
                <Notices />
              </Suspense>
            </S.Container>
            <Flex.Column gap={60} style={{ flex: 1, maxWidth: 580 }}>
              <Suspense clientOnly fallback={<MainPost.Skeleton />}>
                <MainPost />
              </Suspense>
              <Suspense clientOnly fallback={<Feed.Skeleton />}>
                <Feed />
              </Suspense>
            </Flex.Column>
          </Flex.Row>
        </CommunityLayout>
      </Only.Desktop>

      <Only.Mobile>
        <CommunityLayout>
          <Flex.Column
            gap={{ mobile: 16, desktop: 60 }}
            fullWidth
            align='center'
          >
            <Suspense clientOnly fallback={<MainPost.Skeleton />}>
              <MainPost />
            </Suspense>
            <Suspense clientOnly fallback={<Notices.Skeleton />}>
              <Notices />
            </Suspense>
            <Suspense clientOnly fallback={<Feed.Skeleton />}>
              <Feed />
            </Suspense>
          </Flex.Column>
          <WritePostButton
            size='sm'
            style={{ position: 'fixed', bottom: 8, right: 16, zIndex: 3 }}
            leftAddon={<ActionPlus size={16} color='#EEF3F6' />}
            radius='rounded'
          />
        </CommunityLayout>
      </Only.Mobile>

      <Only.Desktop>
        <Spacer
          orientation='vertical'
          size={134}
          style={{ backgroundColor: neutralDay.gray100 }}
        />
      </Only.Desktop>
      <Only.Mobile>
        <Spacer
          orientation='vertical'
          size={40}
          style={{ backgroundColor: neutralDay.gray100 }}
        />
      </Only.Mobile>
      <Footer />
    </>
  );
}
