import { useLoadableVariationDetail } from '@hackler/react-sdk';
import { LoginProcess } from '@teamsparta/auth-frontend';
import * as CPL from '@teamsparta/cross-platform-logger';
import { Flex, Text } from '@teamsparta/design-system';
import { When } from '@teamsparta/react';
import { useAtom, useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import type { ComponentProps, ReactNode } from 'react';
import { useEffect } from 'react';

import { getUserInfoFromClientCookie } from '@/lib/utils/cookie';
import { isLoginModalShowAtom, nextPageAfterLoginAtom } from '@/store/login';
import { onlineUserIdAtom } from '@/store/user';

import { REBOOT_COURSE_DETAIL } from '../../constants/course';

const INITIAL_TEXT_PROPS = {
  subTitle: '개발자 커리어 개척 캠프',
  title: '항해99',
  desc: '집요한 끈기와 몰입으로\n결국 무엇이든 해내는 개발자로 거듭나세요',
};

export default function Layout({ children }: { children: ReactNode }) {
  const router = useRouter();
  const [, setOnlineUserId] = useAtom(onlineUserIdAtom);

  const [isLoginModalShow, setIsLoginModalShow] = useAtom(isLoginModalShowAtom);
  const nextPageAfterLogin = useAtomValue(nextPageAfterLoginAtom);

  function setOnlineUserIdFromCookie() {
    const { _id } = getUserInfoFromClientCookie();
    setOnlineUserId(_id);
  }

  useEffect(() => {
    setOnlineUserIdFromCookie();
  }, []);

  useEffect(() => {
    function handleResize() {
      let vw = document.documentElement.clientWidth / 100;
      document.documentElement.style.setProperty('--vw', `${vw}px`);
    }

    handleResize();

    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <When
        condition={router.pathname === REBOOT_COURSE_DETAIL.reboot.landingUrl}
        fallback={
          <LoginProcess
            isShow={isLoginModalShow}
            setIsShow={setIsLoginModalShow}
            nextPage={nextPageAfterLogin}
            initialTextProps={INITIAL_TEXT_PROPS}
            CPLogger={CPL}
          />
        }
      >
        <RebootLoginProcessByABTest
          isShow={isLoginModalShow}
          setIsShow={setIsLoginModalShow}
          nextPage={nextPageAfterLogin}
          initialTextProps={INITIAL_TEXT_PROPS}
          CPLogger={CPL}
        />
      </When>
      {children}
    </>
  );
}

const REBOOT_B_INITIAL_TEXT_PROPS = {
  desc: ' ',
  titleComponent: (
    <Flex direction='column' align='center' gap={6}>
      <Text as='p' font={{ mobile: 'mBody2', desktop: 'wBody2' }}>
        11주 취업 특화 트레이닝
      </Text>
      <Text as='h3' font={{ mobile: 'mHeader2', desktop: 'wTitle1' }}>
        지금 로그인하고
        <br />
        최대 할인 받기
      </Text>
    </Flex>
  ),
};

function RebootLoginProcessByABTest(
  props: ComponentProps<typeof LoginProcess>,
) {
  const {
    decision: { variation },
    isLoading,
  } = useLoadableVariationDetail(379);

  if (isLoading) {
    return null;
  }

  return (
    <LoginProcess
      {...props}
      initialTextProps={
        variation === 'B' ? REBOOT_B_INITIAL_TEXT_PROPS : INITIAL_TEXT_PROPS
      }
    />
  );
}
