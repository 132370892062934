import type { ButtonProps } from '@teamsparta/design-system';
import { Button } from '@teamsparta/design-system';

import CustomLink from '@/components/common/CustomLink';
import { LoggingClick } from '@/components/common/LoggingClick';

interface Props extends ButtonProps {}

export function WritePostButton({ ...props }: Props) {
  return (
    <LoggingClick
      logName='hh_community_click'
      data={{ section: '글쓰기', text: '글쓰기' }}
    >
      <CustomLink href='/community/new' fullWidth>
        <Button {...props}>글쓰기</Button>
      </CustomLink>
    </LoggingClick>
  );
}
