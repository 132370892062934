import { useIsClient } from '@/hooks';
import { htmlToText, sanitizeHtml, truncateText } from '@/lib/utils/html';

export function useSafePreviewContent(content: string) {
  const isClient = useIsClient();

  if (!isClient) {
    return content;
  }

  /**
   * @todo community pipe 생각해보기
   */
  const sanitizedContent = sanitizeHtml(content);
  const contentText = htmlToText(sanitizedContent);
  const truncatedContent = truncateText({
    text: contentText ?? '',
    maxLength: 150,
  });

  return truncatedContent;
}
