import {
  additional,
  Button,
  Flex,
  Text,
  ThemeProvider,
} from '@teamsparta/design-system';
import { When } from '@teamsparta/react';
import Image from 'next/image';

import { useIsDesktop } from '@/hooks';
import { AlertDialog } from '@/pages/community/shared';
import { breakpoints } from '@/styles/themes';

interface Props {
  title?: string;
  description?: string;
  isPending: boolean;
  onDelete: () => void;
}

export function DeleteButton({
  title,
  description,
  isPending,
  onDelete,
}: Props) {
  const isDesktop = useIsDesktop({ breakpoint: breakpoints.largeTablet });

  return (
    <AlertDialog.Root>
      <When
        condition={isDesktop}
        fallback={
          <AlertDialog.Trigger style={{ width: '100%' }}>
            <Flex.Row
              as='button'
              padding='13px 0'
              gap={8}
              aria-label='삭제하기'
              align='center'
              fullWidth
            >
              <Image
                src='/svg/community/trash.svg'
                alt='삭제하기'
                width={16}
                height={16}
              />
              <Text as='button' font='mBody2' color={additional.red80}>
                삭제하기
              </Text>
            </Flex.Row>
          </AlertDialog.Trigger>
        }
      >
        <AlertDialog.Trigger>
          <Button
            leftAddon={
              <Image
                src='/svg/community/trash.svg'
                alt='삭제하기'
                width={16}
                height={16}
              />
            }
            fullWidth
            size='xs'
            colorScheme='tertiary'
            style={{ color: additional.red80 }}
            loading={isPending}
          >
            삭제하기
          </Button>
        </AlertDialog.Trigger>
      </When>
      <AlertDialog.Portal>
        <ThemeProvider mode='dark'>
          <AlertDialog.Overlay />
          <AlertDialog.Content>
            <Flex.Column gap={12} align='start' fullWidth>
              <AlertDialog.Title>{title}</AlertDialog.Title>
              {description && (
                <AlertDialog.Description>{description}</AlertDialog.Description>
              )}
            </Flex.Column>
            <Flex.Row gap={12} fullWidth>
              <AlertDialog.Cancel asChild>
                <Button size='xl' colorScheme='secondary' fullWidth>
                  유지
                </Button>
              </AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button size='xl' fullWidth onClick={onDelete}>
                  삭제
                </Button>
              </AlertDialog.Action>
            </Flex.Row>
          </AlertDialog.Content>
        </ThemeProvider>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
