import { env } from './env';

export function createCDNUrl(path: string) {
  return `${env.NEXT_PUBLIC_CDN_BASE_URL}${path}`;
}

/**
 * 입력된 문자열에서 URL을 추출합니다.
 * @param input 문자열
 * @returns URL 배열
 *
 * @example
 * ```ts
 * const input = 'https://www.google.com, https://www.naver.com, https://www.daum.net';
 * const result = extractUrls(input);
 * console.log(result); // ['https://www.google.com', 'https://www.naver.com', 'https://www.daum.net']
 * ```
 */
export function extractUrls(input: string): string[] {
  const urlRegex =
    /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

  const matches = input.match(urlRegex);

  if (!matches) {
    return [];
  }

  return matches.map((url) => url.trim());
}
