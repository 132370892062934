import { instanceWithAuth } from '@/api/instance';

import type {
  GetProfileResponseDto,
  PatchProfileRequestDto,
  PatchProfileResponseDto,
} from './profile.dto';

export async function getProfile() {
  return await instanceWithAuth.get<GetProfileResponseDto>('/v3/profiles');
}

export async function patchProfile(data: PatchProfileRequestDto) {
  const formData = new FormData();

  formData.append('profileId', data.profileId);
  if (data.nickname) {
    formData.append('nickname', data.nickname);
  }
  if (data.image) {
    formData.append('file', data.image);
  }

  return await instanceWithAuth.patch<PatchProfileResponseDto>(
    '/v3/profiles',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
}
