import styled from '@emotion/styled';

export const MobileOnly = styled.div<{ breakpoint: number }>`
  display: contents;

  ${({ breakpoint }) => `@media (min-width: ${breakpoint}px)`} {
    display: none;
  }
`;

export const DesktopOnly = styled.div<{ breakpoint: number }>`
  display: none;

  ${({ breakpoint }) => `@media (min-width: ${breakpoint}px)`} {
    display: contents;
  }
`;
