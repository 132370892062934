import * as RadixAlertDialog from '@radix-ui/react-alert-dialog';

import * as S from './style';

export const AlertDialog = {
  Root: RadixAlertDialog.Root,
  Trigger: RadixAlertDialog.Trigger,
  Portal: RadixAlertDialog.Portal,
  Overlay: S.AlertDialogOverlay,
  Content: S.AlertDialogContent,
  Title: S.AlertDialogTitle,
  Description: S.AlertDialogDescription,
  Cancel: RadixAlertDialog.Cancel,
  Action: RadixAlertDialog.Action,
};
