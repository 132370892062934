import { Flex, neutralDay, Text } from '@teamsparta/design-system';
import { Separated } from '@teamsparta/react';

import type { Profile } from '@/api/community/profile';
import { Dot } from '@/components/common/Dot';
import { LoggingClick } from '@/components/common/LoggingClick';
import { adjustDateByPurpose, getTimePastText } from '@/lib/utils/date';

import { ProfileImage } from '../../../ProfileImage';

interface NoticeProps {
  postId: string;
  title: string;
  author: Profile;
  createdAt: string;
}

export function DesktopNotice({
  postId,
  title,
  author,
  createdAt,
}: NoticeProps) {
  return (
    <LoggingClick
      logName='hh_community_click'
      data={{ section: '공지사항', clicked_post_id: postId }}
    >
      <Flex.Column gap={2}>
        <Text
          as='span'
          font={{ mobile: 'mBody3', desktop: 'wBody3' }}
          color={neutralDay.gray20}
          style={{
            maxWidth: '180px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            wordBreak: 'break-all',
          }}
        >
          {title}
        </Text>
        <Flex.Row align='center' gap={6}>
          <Separated with={<Dot />}>
            <Flex.Row gap={4} align='center'>
              <ProfileImage
                src={author.imageUrl}
                alt={author.nickname}
                width={16}
                height={16}
              />
              <Text as='span' font='wCaption2' color={neutralDay.gray50}>
                {author.nickname}
              </Text>
            </Flex.Row>
            <Text as='span' font='wCaption2' color={neutralDay.gray50}>
              {getTimePastText(adjustDateByPurpose(createdAt, 'calc'))}
            </Text>
          </Separated>
        </Flex.Row>
      </Flex.Column>
    </LoggingClick>
  );
}
