import { getCookie } from 'cookies-next';
import { useAtomValue, useSetAtom } from 'jotai';
import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { tokenAtom } from '@/store/user';

export function useToken() {
  return useAtomValue(tokenAtom);
}

export function useSetToken() {
  return useSetAtom(tokenAtom);
}

export function TokenProvider({ children }: { children: ReactNode }) {
  const setToken = useSetToken();

  useEffect(() => {
    const token = getCookie('token');
    if (token) {
      setToken({ isLoading: false, token });
    } else {
      setToken({ isLoading: false, token: null });
    }
  }, [setToken]);

  return <>{children}</>;
}
