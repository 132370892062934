import { isServer } from '@teamsparta/utils';
import { useEffect, useState } from 'react';

export const useHash = () => {
  const [hash, setHash] = useState(() => {
    if (isServer()) {
      // 서버 사이드에서는 기본값으로 빈문자열 반환 (초기 상태 설정)
      return '';
    }
    return window?.location?.hash || '';
  });

  useEffect(() => {
    const onHashChange = () => {
      setHash(window.location.hash);
    };

    window.addEventListener('hashchange', onHashChange);
    return () => window.removeEventListener('hashchange', onHashChange);
  }, []);

  return hash;
};
