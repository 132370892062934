import type { SuspenseProps } from 'react';
import { Suspense as ReactSuspense } from 'react';

import { useIsClient } from '@/hooks';

interface Props extends SuspenseProps {
  clientOnly?: boolean;
}

export function Suspense({ children, clientOnly, fallback }: Props) {
  const isClient = useIsClient();

  if (clientOnly && !isClient) {
    return <>{fallback}</>;
  }

  return <ReactSuspense fallback={fallback}>{children}</ReactSuspense>;
}
