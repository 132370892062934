import { queryOptions, useQuery } from '@tanstack/react-query';

import {
  getAllRoundsByCourseName,
  getEnrollableRound,
} from '@/api/common/round';
import type { Course } from '@/types';

export const roundQueryKey = {
  all: ['round'],
  payableRoundByCourseName: (courseName: string) => [
    ...roundQueryKey.all,
    'payable',
    courseName,
  ],
  allRoundsByCourseName: (courseName: string) => [
    ...roundQueryKey.all,
    'all',
    courseName,
  ],
  enrollableRoundByCourseName: (courseName: string) => [
    ...roundQueryKey.all,
    'enrollable',
    courseName,
  ],
};

export const enrollableRoundQuery = (courseName: string) =>
  queryOptions({
    queryKey: roundQueryKey.enrollableRoundByCourseName(courseName),
    queryFn: () => getEnrollableRound(courseName),
  });

export function useGetAllRoundsByCourseName({
  courseName,
  enabled = true,
}: {
  courseName: Course;
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: roundQueryKey.allRoundsByCourseName(courseName),
    queryFn: () => getAllRoundsByCourseName(courseName),
    enabled: !!courseName && enabled,
  });
}
