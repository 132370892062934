import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex, neutralDay } from '@teamsparta/design-system';

const loading = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

export const Wrapper = styled(Flex)`
  display: inline-flex;
  width: 100%;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  line-height: 1;
  user-select: none;
  height: 20px;
  background-color: ${neutralDay.gray95};
  /**
   * safari에서 border-radius, overflow를 같이 사용할 경우 둘 다 적용이 되지 않는 버그를 해결하기 위함.
   * @see https://www.sungikchoi.com/blog/safari-overflow-border-radius/
   */
  z-index: 0;

  ::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    transform: translateX(-100%);
    animation: ${loading} 1.25s ease-in-out infinite;
    background-image: linear-gradient(
      90deg,
      ${neutralDay.gray95} 10%,
      ${neutralDay.gray90} 50%,
      ${neutralDay.gray95} 90%
    );
  }
`;
