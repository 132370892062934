import { instanceV2, instanceWithAuth } from '@/api/instance';

import type {
  DeleteCommunityPostRequestDto,
  GetCommunityNoticesResponseDto,
  GetCommunityOngoingPollPostsResponseDto,
  GetCommunityPostDetailRequestDto,
  GetCommunityPostDetailResponseDto,
  GetCommunityPostsRequestDto,
  GetCommunityPostsResponseDto,
  GetPinnedCommunityPostResponseDto,
  PatchCommunityPostPollRequestDto,
  PatchCommunityPostPollResponseDto,
  PatchCommunityPostRequestDto,
  PatchCommunityPostResponseDto,
  PatchCommunityPostViewIncreaseRequestDto,
  PostCommunityPostRequestDto,
  PostCommunityPostResponseDto,
} from './post.dto';

export function postCommunityPost(request: PostCommunityPostRequestDto) {
  return instanceWithAuth.post<PostCommunityPostResponseDto>(
    '/v3/posts',
    request,
  );
}

export function getCommunityPostDetail({
  postId,
}: GetCommunityPostDetailRequestDto) {
  return instanceWithAuth.get<GetCommunityPostDetailResponseDto>(
    `/v3/posts/id/${postId}`,
  );
}

export function getCommunityPosts({
  cursor,
  category,
}: GetCommunityPostsRequestDto) {
  return instanceWithAuth.get<GetCommunityPostsResponseDto>('/v3/posts', {
    params: {
      cursor,
      category: category === '전체' ? undefined : category,
      limit: 10,
    },
  });
}

export function deleteCommunityPost({ postId }: DeleteCommunityPostRequestDto) {
  return instanceWithAuth.delete(`/v3/posts/${postId}`);
}

export function patchCommunityPost(request: PatchCommunityPostRequestDto) {
  return instanceWithAuth.patch<PatchCommunityPostResponseDto>(
    '/v3/posts',
    request,
  );
}

export function patchCommunityPostPoll({
  postId,
  selectedOptionText,
}: PatchCommunityPostPollRequestDto) {
  return instanceWithAuth.patch<PatchCommunityPostPollResponseDto>(
    '/v3/posts/poll',
    {
      postId,
      selectedOptionText,
    },
  );
}

export function getPinnedCommunityPost() {
  return instanceWithAuth.get<GetPinnedCommunityPostResponseDto>(
    '/v3/posts/pin',
  );
}

export function getCommunityNotices() {
  return instanceWithAuth.get<GetCommunityNoticesResponseDto>(
    '/v3/posts/notice',
  );
}

export function getCommunityOngoingPollPosts() {
  return instanceWithAuth.get<GetCommunityOngoingPollPostsResponseDto>(
    '/v3/posts/poll',
    {
      params: {
        limit: 3,
      },
    },
  );
}

export function patchCommunityPostViewIncrease(
  request: PatchCommunityPostViewIncreaseRequestDto,
) {
  return instanceV2.patch(`/v3/posts/views/${request.postId}`);
}
