import * as RadixDialog from '@radix-ui/react-dialog';

import * as S from './style';

export const Dialog = {
  Root: RadixDialog.Root,
  Overlay: S.DialogOverlay,
  Content: S.DialogContent,
  Title: RadixDialog.Title,
  Trigger: RadixDialog.Trigger,
  Portal: RadixDialog.Portal,
  Close: RadixDialog.Close,
};
