import type { ReactElement, ReactNode } from 'react';
import { Children, isValidElement, useEffect, useMemo, useState } from 'react';

import { assert } from '@/lib/utils/assert';

type NonEmptyArray<T> = readonly [T, ...T[]];

export function useFunnel<Steps extends NonEmptyArray<string>>({
  steps,
  initialStep = steps[0],
  tracingExternalStep = false,
  externalStep,
}: {
  steps: Steps;
  initialStep?: Steps[number];
  tracingExternalStep?: boolean;
  externalStep?: Steps[number];
}) {
  const [step, setStep] = useState<Steps[number]>(initialStep);

  const FunnelComponent = useMemo(
    () =>
      Object.assign(
        function _Funnel(props: Omit<FunnelProps<Steps>, 'step'>) {
          return <Funnel<Steps> step={step} {...props} />;
        },
        {
          Step: function _Step(props: StepProps<Steps>) {
            return <Step<Steps> {...props} />;
          },
        },
      ),
    [step],
  );

  useEffect(() => {
    if (tracingExternalStep) {
      assert(
        externalStep && steps.includes(externalStep),
        '올바른 externalStep이 아닙니다.',
      );
      setStep(externalStep);
    }
  }, [tracingExternalStep, externalStep, steps, initialStep]);

  return [FunnelComponent, setStep, step] as const;
}

type FunnelProps<Steps extends NonEmptyArray<string>> = {
  step: Steps[number];
  children:
    | Array<ReactElement<StepProps<Steps>>> // ReactNode는 Generic을 받지 않는다.
    | ReactElement<StepProps<Steps>>;
};

function Funnel<Steps extends NonEmptyArray<string>>({
  step,
  children,
}: FunnelProps<Steps>) {
  const validElements = Children.toArray(children).filter(isValidElement);
  const targetStepElement = validElements.find(
    (element) => (element.props as StepProps<Steps>).name === step,
  );

  if (!targetStepElement) {
    return null;
  }

  return <>{targetStepElement}</>;
}

type StepProps<Steps extends NonEmptyArray<string>> = {
  name: Steps[number];
  onEnter?: () => void;
  children: ReactNode; // children에 <>content</>같은 jsx가 아니라, 'content'와 같은 문자열로도 할 수 있도록 더 넓은 범위를 아우르는 ReactNode 사용.
};

function Step<Steps extends NonEmptyArray<string>>({
  onEnter,
  children,
}: StepProps<Steps>) {
  useEffect(() => {
    onEnter?.();
  }, [onEnter]);

  return <>{children}</>;
}
