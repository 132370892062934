import styled from '@emotion/styled';

import { LARGE_TABLET } from '@/styles/themes';

export const ChildrenWrapper = styled.main`
  width: 100%;
  max-width: none;

  ${LARGE_TABLET} {
    max-width: 820px;
  }
`;
