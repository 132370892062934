import HackleDevTools from '@hackler/javascript-devtools';
import { createInstance, HackleProvider } from '@hackler/react-sdk';
import { isClient } from '@teamsparta/utils';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { useOnlineUser } from '@/hooks';
import { getDeviceId } from '@/lib/utils/businessLogics/utils/_helper';

const hackleClient = createInstance(process.env.NEXT_PUBLIC_HACKLE_SDK_KEY, {
  auto_track_page_view: true,
  devTool: HackleDevTools,
  autoOpenDevTool: !JSON.parse(process.env.IS_PROD),
});

export function Hackle({ children }: { children: ReactNode }) {
  const onlineUserInfo = useOnlineUser();

  const user = useMemo(
    () => ({
      id: isClient() ? getDeviceId() : undefined,
      deviceId: isClient() ? getDeviceId() : undefined,
      userId: onlineUserInfo?._id,
    }),
    [onlineUserInfo],
  );

  return (
    <HackleProvider hackleClient={hackleClient} user={user} supportSSR>
      {children}
    </HackleProvider>
  );
}
