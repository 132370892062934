import {
  ArrowLeftSmallLine,
  Flex,
  neutralDay,
  Text,
} from '@teamsparta/design-system';
import Link from 'next/link';

export function BackToFeed() {
  return (
    <Link href='/community'>
      <Flex.Row
        align='center'
        gap={4}
        padding={{ mobile: '0 16px', desktop: 0 }}
      >
        <ArrowLeftSmallLine size={16} color={neutralDay.gray40} />
        <Text as='span' font='wCaption1' color={neutralDay.gray40}>
          목록으로
        </Text>
      </Flex.Row>
    </Link>
  );
}
