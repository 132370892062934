import styled from '@emotion/styled';
import { neutralDay, wBody4 } from '@teamsparta/design-system';

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 16px;
  border-radius: 8px;
  border: 1px solid ${neutralDay.gray80};
  gap: 12px;

  &:focus-within {
    border-color: ${neutralDay.gray30};
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  ${wBody4};
  color: ${neutralDay.white};
  border: none;
  background: transparent;
  resize: none;
  outline: none;
  font-size: 16px;
  transform: scale(0.875);
  transform-origin: left top;
  position: relative;
  top: 2px;

  &:focus {
    border: none;
  }
`;

export const Label = styled.label``;
