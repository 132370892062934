import { instanceWithAuth } from '@/api/instance';

import type { PostCommunityLikeRequestDto } from './like.dto';

/**
 * @description 이 mutation 1개로 Post, Comment, SubComment 좋아요 처리
 */
export function postCommunityLike(request: PostCommunityLikeRequestDto) {
  return instanceWithAuth.post('/v3/likes/toggle', request);
}
