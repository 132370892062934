import {
  ContentsEdit,
  Flex,
  neutralDay,
  Text,
} from '@teamsparta/design-system';
import { useSetAtom } from 'jotai';

import type { GetProfileResponseDto } from '@/api/community/profile';
import { LoggingClick } from '@/components/common/LoggingClick';
import { useOnlineUser } from '@/hooks';
import { logout } from '@/lib/utils/businessLogics/login/login';
import { ProfileImage } from '@/pages/community';
import { useEditProfileModal } from '@/store/community';
import { MobileGNBOpenAtom } from '@/store/landing';

interface Props {
  profile?: GetProfileResponseDto;
}

export function MobileGNBHeaderForCommunity({ profile }: Props) {
  const onlineUser = useOnlineUser();

  const setMobileGNBOpen = useSetAtom(MobileGNBOpenAtom);
  const { open } = useEditProfileModal();

  function handleClickEditProfile() {
    open();
    setMobileGNBOpen(false);
  }

  function handleClickLogout() {
    logout();
  }

  return (
    <Flex.Row padding='32px 16px 30px' gap={8} align='center'>
      <ProfileImage
        src={profile?.imageUrl}
        alt='프로필 이미지'
        width={48}
        height={48}
      />
      <Flex.Column style={{ flex: 1 }} gap={2}>
        <Text as='span' font='mTitle1' color={neutralDay.white}>
          {onlineUser?.name} 님, 안녕하세요.
        </Text>
        <Flex.Row gap={4} align='center'>
          <Text as='span' font='mCaption1' color={neutralDay.gray40}>
            {profile?.nickname}
          </Text>
          <LoggingClick
            logName='hh_hub_menuHamburger_click'
            data={{
              menu_title: '프로필 수정',
            }}
          >
            <Flex as='button' onClick={handleClickEditProfile}>
              <ContentsEdit size={16} color={neutralDay.gray70} />
            </Flex>
          </LoggingClick>
        </Flex.Row>
      </Flex.Column>
      <Text
        as='button'
        font='mCaption2'
        color={neutralDay.gray60}
        style={{ padding: '12px 0' }}
        onClick={handleClickLogout}
      >
        로그아웃
      </Text>
    </Flex.Row>
  );
}
