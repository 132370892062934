import { useEffect } from 'react';

// 원래 스타일을 전역으로 저장
let originalStyle: string | null = null;

/**
 * 스크롤을 제한시키는 훅
 * @description 모달이나 팝업이 열렸을 때 스크롤을 제한시키기 위해 사용
 * @param isLocked 스크롤을 제한할지 여부
 * @todo community 커뮤니티 작업하면서 수정함. 주석 추가 필요
 * 기존엔 모바일GNB를 열고 프로필 수정 아이콘을 누르면 스크롤이 잠기는 버그가 있었음.
 */
export function useLockBodyScroll(isLocked: boolean) {
  useEffect(() => {
    if (isLocked) {
      // 최초 한 번만 원래 스타일을 저장
      if (originalStyle === null) {
        originalStyle = window.getComputedStyle(document.body).overflow;
      }
      document.body.style.overflow = 'hidden';

      return () => {
        // cleanup에서 원래 스타일로 복원
        document.body.style.overflow = originalStyle || 'auto';
        // 모든 lock이 해제되었을 때만 originalStyle 초기화
        if (!document.body.style.overflow.includes('hidden')) {
          originalStyle = null;
        }
      };
    }
    return () => {};
  }, [isLocked]);
}
